import { mkenum } from "../../utils/mkenum";

export const ImpactType = mkenum({
  trees: "trees",
  co2e: "co2e",
  plastic: "plastic",
});

export type ImpactType = (typeof ImpactType)[keyof typeof ImpactType];

export const impactTypes = Object.values(ImpactType) as ImpactType[];
