import type { UpdatedByService } from "@s2z-platform/constants";

export class UpdatedByJSON {
  id?: number;
  name?: string;
  /**
   * Does not exist if the user made action himself
   */
  actor?: {
    /** admin id or 0 */
    id: number;
    /** admin name or 's2z' if id = 0 */
    name: string | "s2z";
    /** does not exist if id != 0 */
    service?: UpdatedByService;
  };
}
