export const milesToKmConversion = 1.609344;

export const vehicleLookupFieldOrder = [
  "fuelType",
  "transmission",
  "make",
  "productionYear",
  "baseModelName",
  "modelName",
  "engineSizeL",
];

export const ghgEstimateFieldOrder = [
  "fuelType",
  "reportingYear",
  "vehicleCategory",
];

export const hgvGhgEstimateFieldOrder = [
  "fuelType",
  "reportingYear",
  "chassis",
  "tonnageCat",
  "laden",
  "refrigerated",
];

export const fuelTypeOrder = [
  "petrol",
  "diesel",
  "hybrid",
  "bev",
  "phev",
  "ffv",
  "cng",
  "lpg",
];

export const registrationConfirmFieldOrder = ["baseModelName", "modelName"];
