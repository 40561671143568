import type { FootprintConfigJSON } from "../footprint";
import type { UserMetricsConfigJSON } from "./user-metrics-config.json";
import type { UserNotificationsJSON } from "./user-notifications.json";

/**
 * NB: anything added here must also be added to:
 * - UserMetadataDto
 * - UserService.apiUpdateMetadata()
 */
export class UserMetadataJSON {
  footprintConfig?: FootprintConfigJSON;
  metricsConfig?: UserMetricsConfigJSON;
  notifications?: UserNotificationsJSON;
}
