import {
  CognitoHostedUIIdentityProvider,
  CognitoUser,
} from "@aws-amplify/auth";
import { Auth, Hub, Amplify } from "aws-amplify";
import { isEqual } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useAppDispatch } from "../../store/hooks";
import { enqueueErrorMessage } from "../../store/slices/appSlice";
import { logout as authLogout } from "../../store/slices/authSlice";

export type UseOAuthArgs = {
  provider: string;
  redirectSignIn: string;
  redirectSignOut: string;
};

let origin = "";
if (typeof window !== "undefined") {
  origin = window.location.origin;
}

export const buildDefaultConfig = ({
  redirectSignIn = origin,
  redirectSignOut = origin,
}: {
  redirectSignIn?: string;
  redirectSignOut?: string;
}) => ({
  Auth: {
    userPoolId: process.env.REACT_APP_COGNITO_ADMIN_USER_POOL_ID,
    identityPoolRegion:
      process.env.REACT_APP_COGNITO_ADMIN_IDENTITY_POOL_REGION,
    userPoolWebClientId:
      process.env.REACT_APP_COGNITO_ADMIN_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_ADMIN_OAUTH_DOMAIN,
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn,
      redirectSignOut,
      responseType: "token",
    },
  },
});

const useOAuth = ({
  provider,
  redirectSignIn,
  redirectSignOut,
}: UseOAuthArgs) => {
  const intl = useIntl();
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [customState, setCustomState] = useState(null);
  const defaultConfig = useMemo(
    () => buildDefaultConfig({ redirectSignIn, redirectSignOut }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const dispatch = useAppDispatch();

  const handleSignInFailure = ({ data }: any) => {
    let msg;
    // check if the login failed du to trigger validation
    if (
      /^(PreAuthentication|PreSignUp)/.test(data.message) &&
      /status\+code\+400/.test(data.message)
    ) {
      msg = intl.formatMessage({ id: "login.error.inexistant_account" });
    } else {
      msg = intl.formatMessage({ id: "login.error.login_issue" });
    }
    dispatch(enqueueErrorMessage(msg));
  };

  useEffect(() => {
    Amplify.configure(defaultConfig);

    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          if (!isEqual(user, data)) {
            setUser(data);
          }
          break;
        case "signOut":
        case "oAuthSignOut":
          setUser(null);
          dispatch(authLogout());
          break;
        case "customOAuthState":
          setCustomState(data);
          break;
        case "signIn_failure":
          // eslint-disable-next-line no-console
          console.info("Sign in failure", { event, data });
          handleSignInFailure({ event, data });
          break;
        default:
      }
    });

    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        if (!isEqual(currentUser, user)) {
          setUser(currentUser);
        }
      })
      .catch(() => {});

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, redirectSignIn, redirectSignOut]);

  const logout = async () => {
    const config = defaultConfig;
    config.Auth.oauth.redirectSignOut = redirectSignOut;

    Auth.configure(config);
    const res = await Auth.signOut();

    return res;
  };

  const login = async () => {
    const config = defaultConfig;
    config.Auth.oauth.redirectSignIn = redirectSignIn;
    Auth.configure(config);
    const res = await Auth.federatedSignIn({
      provider:
        provider === "facebook"
          ? CognitoHostedUIIdentityProvider.Facebook
          : CognitoHostedUIIdentityProvider.Google,
    });

    return res;
  };

  return {
    user,
    setUser,
    customState,
    CognitoAuth: Auth,
    login,
    logout,
  };
};

export default useOAuth;
