import { mkenum } from "../utils/mkenum";

export const BillingType = mkenum({
  subscription: "subscription",
  single_purchase: "single_purchase",
  offer: "offer",
});

export type BillingType = (typeof BillingType)[keyof typeof BillingType];

export const billingTypes = Object.values(BillingType) as BillingType[];
