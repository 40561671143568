export type VehicleInputDataCommonLookup = {
  s2zId: string;
  transmission: "automatic" | "manual";
  make: string;
  productionYearStart: string;
  productionYearEnd: string;
  baseModelName: string;
  modelName: string;
  engineSizeL: string;
  gCo2ePerKm: string;
};

export type VehicleInputDataUkHgv = {
  vehicleType: "hgv";
  fuelType: string;
  reportingYear: string;
  refrigerated: string;
  chassis: string;
  tonnageCat: string;
  laden: string;
  gCo2ePerKm: string;
};

export type VehicleInputDataUkGeneral = {
  market: "uk";
  vehicleType: string;
  fuelType: string;
  reportingYear: string;
  vehicleCategory: string;
  gCo2ePerKm: string;
};

export type TransformedVehicleInputGeneral = {
  reportingYear: number;
  gCo2ePerKm: number;
};

export type TransformedVehicleInputLookup = {
  productionYearStart: number;
  productionYearEnd?: number;
  gCo2ePerKm?: number;
  s2zId: number;
};

export type TransformedVehicleInputDataUkGeneral = Omit<
  VehicleInputDataUkGeneral,
  "reportingYear" | "gCo2ePerKm"
> &
  TransformedVehicleInputGeneral;

export type TransformedVehicleInputDataUkHgv = Omit<
  VehicleInputDataUkHgv,
  "reportingYear" | "gCo2ePerKm"
> &
  TransformedVehicleInputGeneral;

export type TransformedVehicleInputDataUsGeneral = Omit<
  VehicleInputDataUsGeneral,
  "reportingYear" | "gCo2ePerKm"
> &
  TransformedVehicleInputGeneral;

export type TransformedVehicleInputCommonLookup = Omit<
  VehicleInputDataCommonLookup,
  | "s2zId"
  | "productionYearStart"
  | "productionYearEnd"
  | "engineSizeL"
  | "gCo2ePerKm"
> &
  TransformedVehicleInputLookup;

export type VehicleInputDataUsGeneral = {
  market: "us";
  vehicleType: string;
  fuelType: string;
  reportingYear: string;
  gCo2ePerKm: string;
};

export function transformJsonUkGeneralData(
  input: Array<VehicleInputDataUkGeneral>,
): Array<TransformedVehicleInputDataUkGeneral> {
  return input.map((record) => {
    const { gCo2ePerKm, reportingYear } = record;
    return {
      ...record,
      gCo2ePerKm: parseFloat(gCo2ePerKm),
      reportingYear: parseInt(reportingYear, 10),
    };
  });
}

export function transformJsonUsGeneralData(
  input: Array<VehicleInputDataUsGeneral>,
): Array<TransformedVehicleInputDataUsGeneral> {
  return input.map((record) => {
    const { gCo2ePerKm, reportingYear } = record;
    return {
      ...record,
      gCo2ePerKm: parseFloat(gCo2ePerKm),
      reportingYear: parseInt(reportingYear, 10),
    };
  });
}

export function transformJsonUkHgvData(
  input: Array<VehicleInputDataUkHgv>,
): Array<TransformedVehicleInputDataUkHgv> {
  return input.map((record) => {
    const { gCo2ePerKm, reportingYear } = record;
    return {
      ...record,
      gCo2ePerKm: parseFloat(gCo2ePerKm),
      reportingYear: parseInt(reportingYear, 10),
    };
  });
}

export function transformJsonUkLookupData(
  input: Array<VehicleInputDataCommonLookup>,
): Array<TransformedVehicleInputCommonLookup> {
  return input.map((record) => {
    const {
      gCo2ePerKm,
      productionYearStart,
      productionYearEnd,
      s2zId,
      engineSizeL,
    } = record;

    return {
      ...record,
      gCo2ePerKm: gCo2ePerKm ? parseFloat(gCo2ePerKm) : undefined,
      productionYearStart: parseInt(productionYearStart, 10),
      productionYearEnd: productionYearEnd
        ? parseInt(productionYearEnd, 10)
        : undefined,
      s2zId: parseInt(s2zId, 10),
      engineSizeL: parseFloat(engineSizeL),
    };
  });
}

export function transformJsonUsLookupData(
  input: Array<VehicleInputDataCommonLookup>,
): Array<TransformedVehicleInputCommonLookup> | unknown {
  return input.map((record) => {
    const {
      gCo2ePerKm,
      productionYearStart,
      productionYearEnd,
      s2zId,
      engineSizeL,
    } = record;

    return {
      ...record,
      gCo2ePerKm: gCo2ePerKm ? parseFloat(gCo2ePerKm) : undefined,
      productionYearStart: parseInt(productionYearStart, 10),
      productionYearEnd: productionYearEnd
        ? parseInt(productionYearEnd, 10)
        : undefined,
      s2zId: parseInt(s2zId, 10),
      engineSizeL: parseFloat(engineSizeL),
    };
  });
}
