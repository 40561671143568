import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Averta Light",
    fontWeight: 300,
    alignItems: "flex-start",
  },
  pink: {
    color: theme.palette.pink.contrastText,
    backgroundColor: theme.palette.pink.main,
  },
  blue: {
    color: theme.palette.blue.contrastText,
    backgroundColor: theme.palette.blue.main,
  },
  grey: {
    color: theme.palette.blue.contrastText,
    backgroundColor: "#B8B8B8",
  },
  large: {
    width: 70,
    height: 70,
    fontSize: 30,
  },
  neutral: {
    color: theme.palette.dark.contrastText,
    backgroundColor: theme.palette.dark.main,
  },
  medium: {
    width: 50,
    height: 50,
    fontSize: 22,
  },
  small: {
    width: 35,
    height: 35,
    fontSize: 15,
  },
  xs: {
    width: 30,
    height: 30,
    fontSize: 10,
  },
  altText: {
    marginBottom: 0,
    "&.lg": {
      paddingTop: 22,
    },
    "&.md": {
      paddingTop: 16,
    },
    "&.sm": {
      paddingTop: 11,
    },
    "&.xs": {
      paddingTop: 11,
    },
  },
}));

export type CustomAvatarProps = {
  /**
   * Avatar placeholder
   */
  alt: string;
  /**
   * Avatar picture
   */
  src?: string;
  /**
   * Avatar size
   */
  size: "lg" | "md" | "sm" | "xs";
  /**
   * Background color
   */
  color: "pink" | "blue" | "grey" | "neutral";
};

/**
 * Avatar component
 */
const CustomAvatar = ({
  src = "",
  alt,
  color = "neutral",
  size = "lg",
}: CustomAvatarProps) => {
  const classes = useStyles();

  return (
    <Avatar
      className={clsx(
        classes.root,
        color === "pink" && classes.pink,
        color === "blue" && classes.blue,
        color === "grey" && classes.grey,
        color === "neutral" && classes.neutral,
        size === "lg" && classes.large,
        size === "md" && classes.medium,
        size === "sm" && classes.small,
        size === "xs" && classes.xs
      )}
      src={src}
    >
      <p className={clsx(classes.altText, size)}>{alt}</p>
    </Avatar>
  );
};

export default CustomAvatar;
