import type {
  CalculatorCountry,
  CalculatorSector,
  SupportedCurrencyCode,
  FREQUENCY_OPTIONS,
} from "@s2z-platform/constants";

export type RevenuePeriod = (typeof FREQUENCY_OPTIONS)[number];

export class EstimateUserProvidedValuesJSON {
  country: CalculatorCountry;
  sectorName: CalculatorSector;
  revenue: number;
  currency: SupportedCurrencyCode;
  revenuePeriod: RevenuePeriod;
}
``;
