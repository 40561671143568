import {
  EvidenceFileTypeEnum,
  EvidenceMimeTypeEnum,
  UserImageFileTypeEnum,
  UserImageMimeTypeEnum,
} from "@s2z-platform/constants";

export function checkEvidenceFileName(value: string) {
  return (Object.keys(EvidenceFileTypeEnum) as EvidenceFileTypeEnum[]).some(
    (type) => value.toLowerCase().endsWith(`.${type}`),
  );
}

export function checkEvidenceMimeType(value: string) {
  return (Object.keys(EvidenceMimeTypeEnum) as EvidenceMimeTypeEnum[]).some(
    (acceptedMimeType) => value === acceptedMimeType,
  );
}

export function checkUserImageFileName(value: string) {
  return (Object.keys(UserImageFileTypeEnum) as UserImageFileTypeEnum[]).some(
    (type) => value.toLowerCase().endsWith(`.${type}`),
  );
}

export function checkUserImageMimeType(value: string) {
  return (Object.keys(UserImageMimeTypeEnum) as UserImageMimeTypeEnum[]).some(
    (acceptedMimeType) => value === acceptedMimeType,
  );
}
