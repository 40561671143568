import { mkenum } from "../utils/mkenum";

export const UserImageFeatureEnum = mkenum({
  impactPage: "impactPage",
});

export type UserImageFeatureEnum =
  (typeof UserImageFeatureEnum)[keyof typeof UserImageFeatureEnum];

export const USER_IMAGE_SUPPORTED_MIME_TYPES_AND_EXTENSIONS = {
  "image/png": ["png"],
} as const;

const supportedMimeTypes = Object.keys(
  USER_IMAGE_SUPPORTED_MIME_TYPES_AND_EXTENSIONS,
) as (keyof typeof USER_IMAGE_SUPPORTED_MIME_TYPES_AND_EXTENSIONS)[];

const supportedFileExtensions = Object.values(
  USER_IMAGE_SUPPORTED_MIME_TYPES_AND_EXTENSIONS,
).flat() as (typeof USER_IMAGE_SUPPORTED_MIME_TYPES_AND_EXTENSIONS)[keyof typeof USER_IMAGE_SUPPORTED_MIME_TYPES_AND_EXTENSIONS][number][];

export const UserImageMimeTypeEnum = mkenum(
  Object.fromEntries(supportedMimeTypes.map((v) => [v, v])),
);
export type UserImageMimeTypeEnum =
  (typeof UserImageMimeTypeEnum)[keyof typeof UserImageMimeTypeEnum];

export const UserImageFileTypeEnum = mkenum(
  Object.fromEntries(supportedFileExtensions.map((v) => [v, v])),
);
export type UserImageFileTypeEnum =
  (typeof UserImageFileTypeEnum)[keyof typeof UserImageFileTypeEnum];
