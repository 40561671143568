export const pathPrefix = "/admin";

// Base URLS
export const IMG_BASE_URL = `${process.env.PUBLIC_URL}/images/`;

export const HOME_PAGE = `${pathPrefix}/`;

// LOGIN
export const LOGIN_PATH = "/login";

export const USER_LIST_PATH = `${pathPrefix}/users`;

// Account
export const ACCOUNT_SETTINGS = `${pathPrefix}/account/settings`;
