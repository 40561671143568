import { mkenum } from "../../utils/mkenum";

export const CalculatorSector = mkenum({
  accommodation:
    "Hotels, holiday & short-stay accommodation, camping grounds & caravan parks",
  accounting_audit_tax: "Accounting, auditing and tax consultancy",
  advertising_market_research: "Advertising and market research services",
  agriculture_hunting: "Agriculture, hunting and related services",
  air_transport: "Passenger & freight air transport services",
  aircraft_spacecraft: "Air and spacecraft and related machinery",
  aircraft_spacecraft_repair: "Repair & maintenance of aircraft  & spacecraft",
  aluminium: "Aluminium production",
  animal_feeds: "Prepared animal feeds",
  architecture_engineering:
    "Architectural and engineering services; technical testing and analysis services",
  baked_goods: "Bakery and farinaceous products",
  beverages: "Beverages, alcoholic & non-alcoholic",
  building_landscape_services: "Services to buildings and landscape",
  care_services: "Residential care services",
  cement_lime_plaster: "Cement, lime, plaster",
  civil_construction:
    "Constructions and construction works for civil engineering",
  cleaning: "Cleaning & toilet preparations",
  clothing: "Clothing apparel",
  coal_lignite: "Coal and lignite",
  computer_electronic_optical:
    "Computer, electronic, communication and optical products",
  computer_personal_goods_repair:
    "Repair services of computers and personal and household goods",
  computing: "Computer programming, consultancy and related services",
  concrete_cement: "Articles of concrete, cement and plaster",
  construction: "Buildings and building construction works",
  creative_arts_entertainment: "Creative, arts and entertainment services",
  cultural_institutions: "Library, archive, museum and other cultural services",
  dairy: "Dairy products",
  data: "Information & data services",
  dyestuffs_agrochemicals: "Dyestuffs, agro-chemicals",
  education: "Education",
  electrical_equipment: "Electrical equipment",
  electricity_production:
    "Electricity production - gas, coal, nuclear, oil, other",
  employment_services: "Employment services",
  fabricated_metal:
    "Fabricated metal products, except machinery and equipment, weapons and ammunition",
  fee_contract_real_estate: "Real estate activities on a fee or contract basis",
  fertiliser: "Fertilisers",
  film_tv_sound_production:
    "Film, video and television production services, sound recording and music publishing",
  finance_auxiliary_services:
    "Services auxiliary to financial services and insurance services",
  financial_services:
    "Financial services, except insurance and pension funding",
  fishing: "Fishing and related services",
  food_services: "Restaurants, catering and bars",
  forestry: "Forestry, logging and related services",
  fruit_veg: "Fruit and vegetables",
  furniture: "Furniture",
  gambling: "Gambling and betting services",
  gaseous_fuel_distribution:
    "Distribution of gaseous fuels through mains and steam and air conditioning supply",
  glass_clay_ceramics:
    "Glass, refractory, clay, other porcelain and ceramic products, Stone, & abrasive products",
  grain_starch: "Grain mill products, starches and starch products",
  health_services: "Human health services",
  industrial_gases:
    "Industrial gases and non-nitrogen-based inorganic chemicals",
  insurance: "Insurance & Reinsurance",
  iron_steel: "Iron & Steel products",
  leather: "Leather and related products",
  legal: "Legal activities",
  machinery_equipment: "Machinery and equipment n.e.c.",
  management_services:
    "Services of head offices; management consulting services",
  meat: "Meat and production of meat products",
  membership_orgs: "Services furnished by membership organisations",
  mining_support: "Mining support services",
  motor_vehicles: "Motor vehicles, trailers and semi-trailers",
  motor_vehicles_retail: "Motor vehicles and motorcycles, including repair",
  office_services:
    "Office administrative, office support and other business support services",
  oils_fats: "Vegetable and animal oils and fats",
  other_chemical: "Other chemical products",
  other_food: "Other food products",
  other_manufacturing: "Other manufactured goods",
  other_metals: "Other metals & casting (excl. Nuclear fuel & Aluminium)",
  other_mining: "Other mining and quarrying products",
  other_nitrogen: "Other nitrogen compounds",
  other_personal_services: "Other personal services",
  other_professional_scientific_technical:
    "Other professional, scientific and technical services",
  other_repair: "Rest of repair; Installation",
  owned_leased_real_estate:
    "Buying and selling of own real estate: renting and operating of own or leased real estate",
  paint_varnish_ink: "Paints, varnishes & ink",
  paper: "Paper and paper products",
  pensions: "Pension funding",
  petrochemicals: "Petrochemicals",
  petrol_gas: "Crude petroleum and natural gas",
  pharmaceutical: "Pharmaceutical products and preparations",
  pipeline_transport: "Transport via pipeline",
  plastic: "Plastic products",
  postal: "Postal and courier services",
  printing_recording: "Printing and recording services",
  public_admin_defence:
    "Public administration and defence; compulsory social security",
  public_transport:
    "Public urban transport; buses, coaches, underground, metro, taxis and other renting of private cars with driver",
  publishing: "Publishing services",
  rail: "Passenger & freight rail transport",
  refined_petrol: "Refined petroleum products",
  rental_services: "Rental and leasing services",
  retail: "Retail trade services (non motor)",
  road_freight: "Freight transport by road and removal services",
  rubber: "Rubber products",
  scientific_research: "Scientific research and development services",
  seafood: "Fish, crustaceans, molluscs",
  security: "Security and investigation services",
  sewerage: "Sewerage services; sewage sludge",
  ship_construction: "Building of ships and boats",
  ship_repair: "Repair & maintenance of ships",
  social_work: "Social work services without accommodation",
  specialised_construction: "Specialised construction works",
  sporting_amusement_recreation:
    "Sporting services and amusement and recreation services",
  telecommunications: "Telecommunications services",
  textiles: "Textiles",
  tobacco: "Tobacco products",
  transport_other:
    "Other transport equipment, excluding ships, boats, air and spacecraft",
  travel_agency_tourism:
    "Travel agency, tour operator and other reservation services",
  tv_radio_broadcasting: "TV & Radio broadcasting services",
  veterinary: "Veterinary services",
  warehousing: "Warehousing and support services for transportation",
  waste_collection:
    "Waste collection, treatment and disposal services; materials recovery services",
  water_remediation: "Remediation services and other waste management services",
  water_supply: "Natural water; water treatment and supply services",
  water_transport: "Water transport services",
  weapons: "Weapons and ammunition",
  wholesale: "Wholesale trade services (non motor)",
  wood_except_furniture: "Wood products, except furniture",
});

export type CalculatorSector = keyof typeof CalculatorSector;

export const CALCULATOR_SECTORS = Object.keys(
  CalculatorSector,
) as CalculatorSector[];
