import type {
  FootprintTarget,
  ImpactIdentifier,
  ReportPeriodLength,
} from "@s2z-platform/constants";
import type { YYYYMMDateString } from "../../common";

export class FootprintReportPeriodJSON {
  start: YYYYMMDateString;
  length: ReportPeriodLength;
}

export class FootprintConfigScopesJSON {
  one: boolean;
  two: boolean;
  three: boolean;
}

export class FootprintConfigFootprintTypesJSON {
  footprint: boolean;
  industryBenchmarks: boolean;
}

export type FootprintConfigImpactsJSON = Partial<
  Record<ImpactIdentifier, boolean>
>;

export class FootprintConfigJSON {
  reportPeriod: FootprintReportPeriodJSON;
  enabledScopes: FootprintConfigScopesJSON;
  enabledFootprintTypes: FootprintConfigFootprintTypesJSON;
  enabledImpacts: FootprintConfigImpactsJSON;
  target: FootprintTarget;
}
