// import type { CurrencyRateEntity } from "../../currency-rate/currency-rate.entity";
// import type { OffsetSettingEntity } from "../offset-setting.entity";
import type { OffsetSettingType } from "@s2z-platform/constants";
import type { ApiStore } from "../api";

type TCurrencyEntity = {
  code: string;
  name: string;
  symbol: string;
  rounding: number;
  decimalDigits: number;
  rateLocked: boolean;
};

type TCurrencyRateEntity = {
  id: number;
  fromCurrency: TCurrencyEntity;
  toCurrency: TCurrencyEntity;
  rate: number;
  createdAt: Date;
  updatedAt: Date;
};

export type OffsetPercentageRange = {
  offsetSetting: TOffsetSettingEntity;
  amountFrom: number;
  amountTo: number;
  percentage: number;
  uuid: string;
};

export type TOffsetSettingEntity = {
  id: number;
  uuid: string;
  createdAt: Date;
  updatedAt: Date;
  store: ApiStore; // StoreEntity;
  type: OffsetSettingType;
  offsetPercentageRanges: OffsetPercentageRange[];
  percentagePerOrder?: number;
  isPercentagePerRange?: boolean;
  treePerOrder?: number;
  isTreePerBatch?: boolean;
  treePerBatchOrderAmount?: number;
  treePerBatchNumberOfTree?: number;
  plantTreeAlways?: boolean;
  avgCheckoutValue?: number;
  avgCheckoutVolume?: number;
  currency?: string;
  updateSchedule?: "legacy" | "live-update";
  runningTotalTrees?: number;
  runningTotalAmount?: number;
  currentMonthTreePrice?: number;
  sendCustomerEmails: boolean;
};

export class CalculationDetailsJSON {
  treeCost?: number;
  offsetSetting: TOffsetSettingEntity; // OffsetSettingEntity;
  currencyRate?: TCurrencyRateEntity; // CurrencyRateEntity;
}
