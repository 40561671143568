/**
 * Home page
 */
const Home = () => (
  <div className=" w-100 d-flex align-items-center justify-content-center">
    <img
      src="https://random-d.uk/api/randomimg"
      alt="duck"
      style={{ maxHeight: "100vh", maxWidth: "100%" }}
    />
  </div>
);

export default Home;
