import { createApi } from "@reduxjs/toolkit/query/react";
import { buildBaseQuery } from "./functions";
import { FeedbackParams } from "../store/queries/common";

type AddingClaimBody = {
  impersonate: number;
};

export type AddingClaimParams = {
  body: AddingClaimBody;
} & FeedbackParams;

export const claimApi = createApi({
  reducerPath: "claimApi",
  baseQuery: buildBaseQuery(),
  endpoints: (builder) => ({
    addAdminClaim: builder.mutation<any, AddingClaimParams>({
      query: ({ body }: AddingClaimParams) => ({
        url: "api/admin/auth/claim",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useAddAdminClaimMutation } = claimApi;
