import {
  AnyAction,
  createListenerMiddleware,
  ListenerEffectAPI,
  ListenerMiddlewareInstance,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import { get } from "lodash";
import { REHYDRATE } from "redux-persist";
import { exampleApi } from "../queries/exampleApi";
import {
  enqueueErrorMessage,
  enqueueMessage,
  hideProgressDialog,
  showProgressDialog,
} from "../slices/appSlice";
import {
  getMe,
  saveTokens,
  setProcessing,
  TokenArgs,
} from "../slices/authSlice";

/**
 * https://redux-toolkit.js.org/api/createListenerMiddleware
 * RTK Listener middleware, used to replace redux-saga
 */
const listenerMiddlewares: ListenerMiddlewareInstance[] = [];

export function showOrHideDialogIfPresent(action: any, show: boolean) {
  const progressDialog = get(
    action,
    "meta.arg.originalArgs.showProgressDialog",
    null
  );
  const aborted = get(action, "meta.aborted", null);
  const condition = get(action, "meta.condition", null);
  const shouldShow = !condition || (condition && aborted);
  if (progressDialog && shouldShow) {
    if (show) {
      return showProgressDialog();
    } else {
      return hideProgressDialog();
    }
  }

  return null;
}

const showProgressEffect = async (
  action: AnyAction,
  listenerApi: ListenerEffectAPI<
    unknown,
    ThunkDispatch<unknown, unknown, AnyAction>,
    unknown
  >
) => {
  const res = showOrHideDialogIfPresent(action, true);
  if (res) {
    listenerApi.dispatch(res);
  }
};

const hideProgressEffect = async (
  action: AnyAction,
  listenerApi: ListenerEffectAPI<
    unknown,
    ThunkDispatch<unknown, unknown, AnyAction>,
    unknown
  >
) => {
  const res = showOrHideDialogIfPresent(action, false);
  if (res) {
    listenerApi.dispatch(res);
  }
};

const showSuccessMessageEffect = async (
  action: AnyAction,
  listenerApi: ListenerEffectAPI<
    unknown,
    ThunkDispatch<unknown, unknown, AnyAction>,
    unknown
  >
) => {
  const messages: string[] = [];
  const successMessage = get(
    action,
    "meta.arg.originalArgs.successMessage",
    null
  );
  const formatSuccessMessage = get(
    action,
    "meta.arg.originalArgs.formatSuccessMessage"
  );

  if (successMessage) {
    messages.push(successMessage);
  }

  if (formatSuccessMessage) {
    try {
      const errorData = get(action, "payload", get(action, "error", null));
      const formattedError = formatSuccessMessage(errorData);
      if (formattedError) {
        if (Array.isArray(formattedError)) {
          messages.push(...formattedError);
        } else {
          messages.push(formattedError);
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn("Unable to format success message", e);
    }
  }

  messages.forEach((msg) => {
    listenerApi.dispatch(
      enqueueMessage({
        body: msg,
        variant: "success",
      })
    );
  });
};

const showErrorMessageEffect = async (
  action: AnyAction,
  listenerApi: ListenerEffectAPI<
    unknown,
    ThunkDispatch<unknown, unknown, AnyAction>,
    unknown
  >
) => {
  const errorMessage = get(action, "meta.arg.originalArgs.errorMessage");
  const formatErrorMessage = get(
    action,
    "meta.arg.originalArgs.formatErrorMessage"
  );
  const messages: string[] = [];
  if (errorMessage) {
    messages.push(errorMessage);
  }
  if (formatErrorMessage) {
    try {
      const errorData = get(action, "payload", get(action, "error", null));
      const formattedError = formatErrorMessage(errorData);
      if (formattedError) {
        if (Array.isArray(formattedError)) {
          messages.push(...formattedError);
        } else {
          messages.push(formattedError);
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn("Unable to format error message", e);
    }
  }

  messages.forEach((msg) => listenerApi.dispatch(enqueueErrorMessage(msg)));
};

[exampleApi.endpoints.getPosts.matchPending].forEach((matcher) => {
  const listenerMiddleware = createListenerMiddleware();
  listenerMiddleware.startListening({
    matcher,
    effect: showProgressEffect,
  });
  listenerMiddlewares.push(listenerMiddleware);
});

const fulfilledMatchers = [exampleApi.endpoints.getPosts.matchFulfilled];

const rejectedMatchers = [exampleApi.endpoints.getPosts.matchRejected];

[...fulfilledMatchers, ...rejectedMatchers].forEach((matcher) => {
  const listenerMiddleware = createListenerMiddleware();
  listenerMiddleware.startListening({
    matcher,
    effect: hideProgressEffect,
  });
  listenerMiddlewares.push(listenerMiddleware);
});

fulfilledMatchers.forEach((matcher) => {
  const listenerMiddleware = createListenerMiddleware();
  listenerMiddleware.startListening({
    matcher,
    effect: showSuccessMessageEffect,
  });
  listenerMiddlewares.push(listenerMiddleware);
});

rejectedMatchers.forEach((matcher) => {
  const listenerMiddleware = createListenerMiddleware();
  listenerMiddleware.startListening({
    matcher,
    effect: showErrorMessageEffect,
  });
  listenerMiddlewares.push(listenerMiddleware);
});

// token middleware
const tokenListenerMiddleware = createListenerMiddleware();
tokenListenerMiddleware.startListening({
  type: saveTokens.type,
  effect: (
    action: AnyAction,
    listenerApi: ListenerEffectAPI<
      unknown,
      ThunkDispatch<unknown, unknown, AnyAction>,
      unknown
    >
  ) => {
    const { accessToken } = action.payload as TokenArgs;
    if (accessToken) {
      listenerApi.dispatch(getMe(accessToken));
    } else {
      // eslint-disable-next-line no-console
      console.warn("No access token found on saveToken");
    }
  },
});
listenerMiddlewares.push(tokenListenerMiddleware);

const hydrateMiddleware = createListenerMiddleware();
hydrateMiddleware.startListening({
  type: REHYDRATE,
  effect: (
    action: AnyAction,
    listenerApi: ListenerEffectAPI<
      unknown,
      ThunkDispatch<unknown, unknown, AnyAction>,
      unknown
    >
  ) => {
    listenerApi.dispatch(setProcessing(false));
  },
});
listenerMiddlewares.push(hydrateMiddleware);

export default listenerMiddlewares;
