// BANANA I hate this guy -BC

/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum ProductTagQuantity {
  WholeTree = 1,
  // TODO: this enum is not used in the allocation endpoint, but what does it encode?
  // Partial tree value looks dodgy...
  PartialTree = 1,
  Co2e100Kg = 100,
  Co2e50kg = 50,
  Plastic100g = 100,
}
