export enum DvlaFuelTypeEnum {
  "PETROL" = "petrol",

  "DIESEL" = "diesel",

  "ELECTRICITY" = "bev",

  "STEAM" = "steam",

  // HAVENT FOUND EXAMPLE TO TEST
  "GAS" = "gas",

  "GAS BI-FUEL" = "gas-bi-fuel",

  "HYBRID ELECTRIC" = "hybrid",

  // HAVENT FOUND EXAMPLE TO TEST
  "GAS DIESEL" = "gas diesel",

  "FUEL CELLS" = "fuel cells",

  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  "ELECTRIC DIESEL" = "hybrid",

  "OTHER" = "other",
}
