import { get, isEmpty } from "lodash";

/**
 * Flatted messages for react-intl
 * input {"a":{"b":"c"}}
 * output {"a.b":"c"}
 * @param {*} nestedMessages
 * @param {*} prefix
 */
export const flattenMessages = (nestedMessages: any, prefix = "") => {
  if (nestedMessages === null) {
    return {};
  }

  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === "string") {
      Object.assign(messages, { [prefixedKey]: value });
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

/**
 * Turn an Object into Query String Parameters
 * input {"a": 1, "b": 2, "c": 3, }
 * output "a=1&b=2&c=3"
 * @param {*} queries
 */
export const getURIQuery = (queries: any) => {
  if (isEmpty(queries)) {
    return "";
  }

  return Object.keys(queries)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(queries[key])}`
    )
    .join("&");
};

/**
 * Checks whether a supplied link URI refers to an external host.
 * Accepts arbitrary types, but will return false unless the URI is a string and points to a host outside the frontend.
 * @param uri The URI to check
 * @returns
 */
export const isExternalURI = (uri: any) => {
  const frontendDomain = process.env.NEXT_PUBLIC_FRONTEND_URL;
  const absoluteURL = new URL(uri, `https://${frontendDomain}`).href;

  if (frontendDomain !== undefined && typeof uri === "string") {
    return !(absoluteURL.split(/[/?#]/)[2] === frontendDomain);
  }
  return false;
};

export const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

// map server error to form field
export const mapErrorToFormFields = (error: any, setError: any) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(error)) {
    // @ts-ignore. @ts-expect-error
    setError(`${key}`, { type: "custom", message: value[0] });
  }
};

export const capitalizeFirstLetter = (word: string) =>
  word ? word.charAt(0).toUpperCase() + word.slice(1) : "";

export const getFormActiveFields = (values: {
  [key: string]: any;
}): string[] => {
  if (typeof values !== "object") {
    return [];
  }

  const res: string[] = [];

  Object.keys(values)
    .filter((k) => values[k] !== null && values[k] !== undefined)
    .forEach((field) => {
      const value = get(values, field);
      let active = false;
      if (Array.isArray(value)) {
        active = value.length > 0;
      }
      if (typeof values === "object") {
        if (
          ["IsEqual", "IsBetween", "LessThan", "GreaterThan"].includes(
            value.type
          )
        ) {
          active =
            Object.keys(value).filter((v) => v !== "type" && value[v]).length >
            0;
        } else {
          active =
            Object.values(value).filter((v) => v !== null && v !== undefined)
              .length > 0;
        }
      } else {
        active = value !== null && value !== undefined;
      }

      if (active) {
        res.push(field);
      }
    });

  return res;
};

export const getUrlSearchParams = (
  searchParams: URLSearchParams
): URLSearchParams => {
  try {
    const windowSearchParams = new URLSearchParams(window.location.search);

    if (searchParams.toString() !== windowSearchParams.toString()) {
      return windowSearchParams;
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}

  return new URLSearchParams(searchParams.toString());
};
