/**
 * Platform ID
 * Used in request headers and to get platform data
 * @type {string}
 */
export const PLATFORM_ID = process.env.REACT_APP_PLATFORM_ID;

/**
 * Platform public key
 * Used in request headers
 * @type {string}
 */
export const PLATFORM_PUBLIC_KEY = process.env.REACT_APP_PLATFORM_PUBLIC_KEY;

/**
 * Possible user role
 * @type {string[]}
 */
export const USER_ROLES = ["buyer", "seller", "guest"];

/**
 * These are the supported document extensions for UserDocument
 * @type {string[]}
 */
export const SUPPORTED_DOCUMENT_EXTENSIONS = [
  "pdf",
  "docx",
  "doc",
  "odt",
  "jpg",
  "png",
  "jpeg",
  "gif",
  "jfif",
  "exif",
  "eiff",
  "bmp",
  "svg",
  "webP",
];

/**
 * Document upload max size
 * @type {number}
 */
export const DOCUMENT_MAX_SIZE = 50 * 1024 * 1024;

/**
 * Custom attribute image max size
 * @type {number}
 */
export const CUSTOM_ATTRIBUTE_IMAGE_MAX_SIZE = 15 * 1024 * 1024;

/**
 * Breakpoint widths
 * Note: MAX_something is the max width plus 1
 * @type {[key: string]: number}
 */
export const BREAKPOINTS = {
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1400,
  MAX_SM: 768,
  MAX_MD: 992,
  MAX_LG: 120,
  MAX_XL: 1400,
};

export const TABLE_DEFAULT_BREAKPOINT = BREAKPOINTS.MAX_MD;
