import type {
  SubscriptionType,
  TransactionType,
} from "@s2z-platform/constants";

export class CompulsorySharedMetadataJSON {
  /** Medusa order ID */
  externalOrderId: string; // MedusaOrderId; // WATCH is this correct type?
}

export class CompulsoryManualMetadataJSON extends CompulsorySharedMetadataJSON {
  manualCreation: true;
}

export class CompulsoryTransactionMetadataJSON extends CompulsorySharedMetadataJSON {
  transactionUuid: string;
  transactionType: TransactionType;
}

export class CompulsoryOfferMetadataJSON extends CompulsorySharedMetadataJSON {
  offerRecordUuid: string;
}

export type CompulsoryImpactMetadataJSON =
  | CompulsoryTransactionMetadataJSON
  | CompulsoryOfferMetadataJSON
  | CompulsoryManualMetadataJSON;

export class TransactionImpactPlatformMetadataJSON extends CompulsoryTransactionMetadataJSON {
  subscriptionUuid?: string;
  subscriptionType?: SubscriptionType;
  partialTreeCost?: number;
}

export class ManualUploadPlatformMetadataJSON {
  file_description: string;
  file_name: string;
}

export class OfferImpactPlatformMetadataJSON extends CompulsoryOfferMetadataJSON {}

export type ImpactPlatformMetadataJSON =
  | TransactionImpactPlatformMetadataJSON
  | OfferImpactPlatformMetadataJSON
  | CompulsoryManualMetadataJSON
  | ManualUploadPlatformMetadataJSON;
