import { mkenum } from "../../utils/mkenum";

export const AccountType = mkenum({
  business: "business",
  personal: "personal",
});

export type AccountType = (typeof AccountType)[keyof typeof AccountType];

export const accountTypes = Object.keys(AccountType) as AccountType[];
