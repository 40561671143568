import { mkenum } from "../../utils/mkenum";

export const TypeUnitCombination = mkenum({
  trees_whole: "trees_whole",
  trees_partial: "trees_partial",
  co2e_kgs: "co2e_kgs",
  plastic_gs: "plastic_gs",
});

export type TypeUnitCombination =
  (typeof TypeUnitCombination)[keyof typeof TypeUnitCombination];

export const acceptedTypeUnitCombinations = Object.values(
  TypeUnitCombination,
) as TypeUnitCombination[];
