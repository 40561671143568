import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { IntlProvider } from "react-intl";
import { PersistGate } from "redux-persist/integration/react";
import enMessages from "./translations/messages.en.json";
import "./assets/scss/style.scss";
import { flattenMessages } from "./utils/functions";
import App from "./App";
import store, { persistor } from "./store/store";
import reportWebVitals from "./reportWebVitals";

// Locale
const appLocale = "en-gb";

// Translations
const messages = {
  [appLocale]: enMessages,
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IntlProvider
          locale={appLocale}
          defaultLocale={appLocale}
          messages={flattenMessages(messages[appLocale])}
        >
          <Router>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </Router>
        </IntlProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
