import { mkenum } from "../../utils/mkenum";

export const SignUpFrom = mkenum({
  SIGN_UP: "signUp",
  LEAD: "lead",
  SUBSCRIPTION_PLAN: "subscriptionPlan",
});

export type SignUpFrom = (typeof SignUpFrom)[keyof typeof SignUpFrom];

export const signUpFromChoices = Object.values(SignUpFrom) as SignUpFrom[];
