import { mkenum } from "../../utils/mkenum";

export const SubscriptionPlanPersona = mkenum({
  adult: "adult",
  child: "child",
  pet: "pet",
});

export type SubscriptionPlanPersona =
  (typeof SubscriptionPlanPersona)[keyof typeof SubscriptionPlanPersona];

export const subscriptionPlanPersonas = Object.values(
  SubscriptionPlanPersona,
) as SubscriptionPlanPersona[];
