import { mkenum } from "../../utils/mkenum";

export const SubscriptionInterval = mkenum({
  month: "month",
  year: "year",
});

export type SubscriptionInterval =
  (typeof SubscriptionInterval)[keyof typeof SubscriptionInterval];

export const SubscriptionIntervals = Object.values(
  SubscriptionInterval,
) as SubscriptionInterval[];
