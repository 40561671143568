import React, { useState } from "react";
import { Navbar, Nav, NavItem } from "reactstrap";
import { NavLink, useLocation, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import { Image } from "react-bootstrap";
import AvatarMenu from "./AvatarMenu";
import * as urls from "../../constants/urls";
// import useSidebarMenu from '../../hooks/useSidebarMenu';
import { IMG_BASE_URL } from "../../constants/urls";

/**
 * Left menu (shows logo and admin part urls)
 */
const MenuLeft = () => {
  const { pathname } = useLocation();

  // const {menuItems} = useSidebarMenu();

  // view data toggler state
  const [viewDataOn, setViewDataOn] = useState(true);

  // view data toggler callback
  const toggleViewData = () => setViewDataOn(!viewDataOn);

  // Check is the menu is active
  const isActive = (item: any) => {
    if (item.pattern && item.pattern.test(pathname)) {
      return true;
    }

    return item.path === urls.HOME_PAGE
      ? pathname === urls.HOME_PAGE
      : pathname.includes(item.path);
  };

  // TODO: get from useSidebarMenu
  const menuItems = [
    {
      path: urls.HOME_PAGE,
      name: "home",
    },
    {
      path: urls.USER_LIST_PATH,
      name: "users",
      pattern: /admin\/users/,
    },
  ];

  return (
    <Navbar className="d-none d-lg-flex flex-nowrap">
      <Nav navbar vertical>
        <div className="d-flex justify-content-between align-items-center pb-4 mb-1">
          <Link to={urls.HOME_PAGE} className="sw-brand navbar-brand">
            <Image
              src={`${IMG_BASE_URL}S2Z Logo.svg`}
              alt="s2z logo"
              width={71}
              height={30}
            />
          </Link>
          <AvatarMenu viewDataOn={viewDataOn} viewDataChange={toggleViewData} />
        </div>
        {menuItems.map((item) => (
          <NavItem active={isActive(item)} key={`menu_${item.path}`}>
            <NavLink
              className={clsx(
                "nav-link text-decoration-none",
                isActive(item) && "active"
              )}
              to={item.path}
            >
              <div className="d-flex align-items-center">
                <span className="icon-wrapper">
                  <i className={clsx("menu-icon", item.name)} />
                </span>
                <span>
                  <FormattedMessage id={`dashboard.menu.${item.name}`} />
                </span>
              </div>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
    </Navbar>
  );
};

export default MenuLeft;
