/* eslint-disable no-nested-ternary */
import React, { ReactNode } from "react";
import { Theme, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import clsx from "clsx";

/* Button Component */

export type CustomButtonProps = {
  /**
   * Button variant primary
   */
  primary?: boolean;
  /**
   * Button font size is small
   */
  small?: boolean;
  /**
   * Button variant danger
   */
  danger?: boolean;
  /**
   * Button variant secondary
   */
  secondary?: boolean;
  /**
   * Button variant outlined
   */
  outlined?: boolean;
  /**
   * Link variant button
   */
  link?: boolean;
  /**
   * Title
   */
  title: string;
  /**
   * Start icon
   */
  icon?: ReactNode;
  /**
   * Button is used in toolbar
   */
  toolbar?: boolean;
  /**
   * Button is a burger menu
   */
  menu?: boolean;
  /**
   * Color secondary light
   */
  light?: boolean;
  /**
   * Button width 100% on mobile
   */
  mobileFullWidth?: boolean;
  /**
   * Custom class name
   */
  className?: string;
  /**
    onClick callback
   */
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  /**
   * styles
   */
  classes: any;
  /**
   * button type
   */
  type?: "submit" | "reset" | "button" | undefined;
  form?: string;
};

const CustomButton = withStyles((theme: Theme) => ({
  root: {
    justifyContent: "flex-start",
    fontWeight: "normal",
    [theme.breakpoints.down("md")]: {
      "&.mobile-full-width": {
        width: "100%",
      },
    },
    "&:hover:not(.has-icon)": {
      textDecoration: "underline",
    },
    "&.MuiButton-contained": {
      fontFamily: "Averta Semibold",
      fontWeight: "bold",
      borderRadius: 40,
      padding: "6px 20px 6px 20px",
      boxShadow: theme.shadows[1],
      minHeight: 40,
      "&:hover": {
        textDecoration: "none",
      },
      "&.has-icon": {
        "& .MuiButton-label": {
          justifyContent: "center",
        },
      },
    },
    "&.MuiButton-outlined": {
      borderRadius: 20,
      fontSize: 14,
      lineHeight: "19px",
      padding: "10px 20px 10px 20px",
      "&:hover": {
        textDecoration: "none",
      },
    },
    "&.MuiButton-outlinedPrimary": {
      borderColor: theme.palette.primary.main,
    },
    "&.MuiButton-text": {
      padding: 0,
      minWidth: "unset",
      lineHeight: "normal",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "& .MuiButton-label": {
        fontFamily: "Averta Semibold",
      },
    },
    "&.menu": {
      backgroundColor: "transparent",
      boxShadow: theme.shadows[0],
      borderRadius: 0,
      paddingTop: 19,
      paddingBottom: 0,
      paddingLeft: 5,
      "& .MuiButton-label": {
        flexDirection: "column",
        height: 25,
      },
      "& div": {
        width: 17,
        height: 2,
        backgroundColor: theme.palette.text.primary,
        marginTop: 2,
        marginBottom: 2,
      },
    },
    "&.danger": {
      backgroundColor: theme.palette.error.main,
      "&:hover": {
        backgroundColor: theme.palette.error.dark,
      },
    },
    "&.light": {
      color: theme.palette.text.secondary,
      fontWeight: "normal",
    },
    "&.secondary": {
      color: theme.palette.text.secondary,
    },
    "&.toolbar": {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.default,
      boxShadow: "none",
      borderRadius: 4,
      minHeight: 30,
      padding: "6px 10px",
      overflow: "hidden",
      "& .MuiButton-label": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        fontSize: 12,
        fontFamily: "Averta Semibold",
        "& .MuiButton-startIcon": {
          marginRight: 7,
        },
      },
      "&:hover, &.active": {
        color: theme.palette.background.paper,
        backgroundColor: theme.palette.text.primary,

        "& .MuiButton-label": {
          color: theme.palette.background.paper,
        },
      },
    },
    "&.small": {
      "& .MuiButton-label": {
        fontSize: 12,
        fontFamily: "Averta Semibold",
      },
    },
    "& .MuiButton-label": {
      alignItems: "center",
    },
    "&:focus": {
      outline: "none",
    },
  },
  startIcon: {
    margin: "0 6px 0 0",
    maxHeight: 27,
  },
}))(
  ({
    primary,
    danger,
    secondary,
    outlined,
    mobileFullWidth,
    link,
    toolbar,
    small,
    icon,
    menu,
    light,
    title,
    className,
    onClick,
    classes,
    type,
    form,
  }: CustomButtonProps) => {
    const buttonColor = primary ? "primary" : !link ? "secondary" : "default";
    const buttonVariant = outlined ? "outlined" : link ? "text" : "contained";
    return (
      <Button
        color={buttonColor}
        type={type}
        form={form}
        variant={buttonVariant}
        classes={{
          root: clsx(classes.root, {
            menu,
            "has-icon": icon,
            "mobile-full-width": mobileFullWidth,
          }),
          startIcon: classes.startIcon,
        }}
        className={clsx(
          classes.root,
          buttonVariant !== "text" && classes.root,
          light && "light",
          secondary && "secondary",
          danger && "danger",
          toolbar && "toolbar",
          small && "small",
          className
        )}
        startIcon={icon}
        onClick={onClick}
      >
        {menu ? (
          <>
            <div />
            <div />
            <div />
          </>
        ) : (
          title
        )}
      </Button>
    );
  }
);

CustomButton.defaultProps = {
  primary: false,
  icon: null,
  outlined: false,
  small: false,
  danger: false,
  secondary: false,
  toolbar: false,
  menu: false,
  light: false,
  link: false,
  mobileFullWidth: false,
  className: "",
  onClick: () => {},
};

export default CustomButton;
