import { mkenum } from "../../utils/mkenum";

// Stripe subscription status
// https://stripe.com/docs/api/subscriptions/object#subscription_object-status
export const OldSubscriptionStatus = mkenum({
  pending: "pending",
  "3ds_pending": "3ds_pending",
  failed: "failed",
  active: "active",
  canceled: "canceled",
});

export type OldSubscriptionStatus =
  (typeof OldSubscriptionStatus)[keyof typeof OldSubscriptionStatus];

export const oldSubscriptionStatuses = Object.values(
  OldSubscriptionStatus,
) as OldSubscriptionStatus[];
