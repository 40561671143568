import { ReactNode, useCallback, useEffect, useState } from "react";
import {
  Toast,
  ToastContainer,
  ToastContainerProps,
  ToastProps,
} from "react-bootstrap";
import Typography from "@material-ui/core/Typography";
import { useIntl } from "react-intl";

export type ToastMessagePayload = {
  id?: number;
  title?: string;
  body: string | ReactNode;
  variant: ToastProps["bg"];
  persist?: boolean;
  delay?: number;
};

export type ToastMessage = {
  id: number;
} & Omit<ToastMessagePayload, "id">;

export type ToastMessagesProps = {
  messages: ToastMessage[];
  onClose: (message: ToastMessage) => void;
  // eslint-disable-next-line react/require-default-props
  toastContainerProps?: ToastContainerProps;
  maxItems?: number;
  // eslint-disable-next-line react/require-default-props
  autoCloseDelai?: number;
};

const ToastMessages = ({
  messages: msgs,
  onClose,
  maxItems,
  toastContainerProps,
  autoCloseDelai = 10000,
}: ToastMessagesProps) => {
  const intl = useIntl();
  const [messages, setMessages] = useState<ToastMessage[]>([]);
  const [displayedAt, setDisplayedAt] = useState<{ [key: string]: number }>({});

  // effect for removing extra message if maxItems is provided
  useEffect(() => {
    const newMsgs = [...msgs];
    if (maxItems && newMsgs.length > maxItems) {
      const removedMessages = newMsgs.splice(0, newMsgs.length - maxItems);
      removedMessages.forEach((msg) => {
        onClose(msg);
      });
    }
    newMsgs.reverse();
    setMessages(newMsgs);
  }, [msgs, maxItems, onClose]);

  // update displayed at for delai tracking
  const updateDisplayedAt = useCallback(
    (msg: ToastMessage) => {
      if (!displayedAt[msg.id]) {
        setDisplayedAt({
          ...displayedAt,
          [msg.id]: new Date().getTime(),
        });
      }
    },
    [displayedAt]
  );

  // fire onClose and remove message from displayedAt
  const handleClose = useCallback(
    (message: ToastMessage) => {
      onClose(message);
      const dAt = { ...displayedAt };
      delete dAt[message.id];
      setDisplayedAt(dAt);
    },
    [displayedAt, onClose]
  );

  // effect for timer for removing items after autoCloseDelai
  useEffect(() => {
    let timerId: any = null;
    if (messages.length > 0) {
      timerId = setInterval(() => {
        const currentTime = new Date().getTime();
        const filterFn = (id: string): boolean => {
          if (currentTime - displayedAt[id] < autoCloseDelai) {
            return false;
          }
          return messages.findIndex((msg) => msg.id === +id) > -1;
        };
        Object.keys(displayedAt)
          .filter(filterFn)
          .forEach((id) => {
            const message = messages.find((msg) => msg.id === +id);
            if (message) {
              handleClose(message);
            }
          });
      }, 1000);
    }

    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [messages, displayedAt, handleClose, autoCloseDelai]);

  return (
    <ToastContainer
      position="top-end"
      containerPosition="fixed"
      {...toastContainerProps}
    >
      {messages &&
        messages.map((message) => {
          updateDisplayedAt(message);
          return (
            <Toast
              delay={message.delay ?? 15000}
              autohide={!message.persist}
              key={message.id}
              bg={message.variant}
              onClose={() => handleClose(message)}
            >
              <Toast.Header>
                <Typography variant="caption" className="font-weight-bold">
                  {message.title
                    ? message.title
                    : intl.formatMessage({
                        id: `toastTitle.${message.variant}`,
                      })}
                </Typography>
              </Toast.Header>
              <Toast.Body>
                <Typography>{message.body?.toString() ?? ""}</Typography>
              </Toast.Body>
            </Toast>
          );
        })}
    </ToastContainer>
  );
};

ToastMessages.defaultProps = {
  maxItems: 3,
};

export default ToastMessages;
