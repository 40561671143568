import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";
import { Auth } from "aws-amplify";
import { RootState } from "../store/store";
import { logout, refreshToken, TokenArgs } from "../store/slices/authSlice";
import { LOGIN_PATH } from "../constants/urls";

const mutex = new Mutex();

export const buildBaseQuery = () =>
  fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: async (headers, { getState }) => {
      const token = (getState() as RootState).auth.accessToken;
      try {
        await Auth.currentSession();
      } catch (e) {
        if (window.location.pathname !== LOGIN_PATH) {
          window.location.href = LOGIN_PATH;
        }
      }
      // If we have a token set in state, let's assume that we should be passing it.
      // Some queries could use custom authorization
      if (token && !headers.get("authorization")) {
        headers.set("authorization", token);
      }

      return headers;
    },
  });

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.accessToken;
    // If we have a token set in state, let's assume that we should be passing it.
    // Some queries could use custom authorization
    if (token && !headers.get("authorization")) {
      headers.set("authorization", token);
    }

    return headers;
  },
});

// https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-re-authorization-by-extending-fetchbasequery
export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it

  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        // try to get a new token
        const refreshResult = await baseQuery(
          {
            url: "/auth/refresh",
            headers: {
              authorization: (api.getState() as RootState).auth.refreshToken,
            },
          },
          api,
          extraOptions
        );
        if (refreshResult.data) {
          // store the new token
          api.dispatch(refreshToken(refreshResult.data as TokenArgs));
          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logout());
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export type ParseParamsOptions = {
  arrayStyle?: "standard" | "comma" | "array";
};

export function parseParams(
  params: any,
  options?: ParseParamsOptions
): URLSearchParams {
  const res = new URLSearchParams();
  const { arrayStyle = "array" } = options ?? {};

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key in params) {
    const value = params[key];
    if (value === "") {
      // eslint-disable-next-line no-continue
      continue;
    }
    if (Array.isArray(value)) {
      if (value.length === 0) {
        // eslint-disable-next-line no-continue
        continue;
      }
      if (arrayStyle === "comma") {
        res.set(key, value.join(","));
      } else if (arrayStyle === "standard") {
        value.forEach((v) => {
          res.append(key, v);
        });
      } else if (arrayStyle === "array") {
        value.forEach((v, i) => {
          res.set(`${key}[${i}]`, v);
        });
      }
    } else {
      res.set(key, value);
    }
  }

  return res;
}
