import { mkenum } from "../utils/mkenum";

export const ReportPeriodLength = mkenum({
  quarter: "quarter",
  year: "year",
});

export type ReportPeriodLength =
  (typeof ReportPeriodLength)[keyof typeof ReportPeriodLength];

export const reportPeriods = Object.values(
  ReportPeriodLength,
) as ReportPeriodLength[];
