import { mkenum } from "../utils/mkenum";

export const EventName = mkenum({
  "verify-email-global": "verify-email-global",
  "sign-up": "sign-up",
  "merchant-customer-onboarding": "merchant-customer-onboarding",
});

export type EventName = (typeof EventName)[keyof typeof EventName];

export const eventNames = Object.keys(EventName) as EventName[];
