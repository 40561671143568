import { mkenum } from "../../utils/mkenum";

export const SubscriptionPlanCategory = mkenum({
  ultimate: "ultimate",
  lite: "lite",
  standard: "standard",
  traveler: "traveler",
});

export type SubscriptionPlanCategory =
  (typeof SubscriptionPlanCategory)[keyof typeof SubscriptionPlanCategory];

export const subscriptionPlanCategories = Object.values(
  SubscriptionPlanCategory,
) as SubscriptionPlanCategory[];
