import type { TransformFnParams } from "class-transformer";
import { endOfDay, endOfMonth, startOfDay, startOfMonth } from "date-fns";
import type {
  DateRange,
  YYYYMMDDDateString,
  YYYYMMDateString,
} from "@s2z-platform/types";
import { YYYYMMDDDateRegex, YYYYMMDateRegex, convertToYYYYMMDD } from "./utils";

// ColumnNumericTransformer
// https://stackoverflow.com/questions/69872250/typeorm-decimal-column-values-returned-as-strings-instead-of-decimal-numbers/70127622#70127622
export class ColumnNumericTransformer {
  to(data: number): number {
    return data;
  }

  from(data: string): number {
    return parseFloat(data);
  }
}

export const ParseDateTransform = (
  { value }: TransformFnParams,
  dateTransform?: (d: Date) => Date,
) => {
  if (typeof value !== "string") return null;
  return dateTransform ? dateTransform(new Date(value)) : new Date(value);
};

export const ParseDateWithRegexTest =
  (regex: RegExp, dateTransform?: (d: Date) => Date) =>
  (input: TransformFnParams) => {
    if (!regex.test(input.value)) return null;

    return ParseDateTransform(input, dateTransform);
  };

export const parseIntTransform = ({ value }: { value: string }) =>
  value ? parseInt(value, 10) : value;

export const parseFloatTransform = ({ value }: { value: string }) =>
  value ? parseFloat(value) : value;

export const parseBooleanTransform = ({
  value,
}: {
  value: string | boolean;
}) => {
  if (typeof value === "boolean") {
    return value;
  }

  if (value.toLowerCase() === "true") {
    return true;
  }
  if (value.toLowerCase() === "false") {
    return false;
  }
  return undefined;
};

export function parseYYYYMMOrYYYYMMDDDateRangeToYYYYMMDD(
  input: TransformFnParams,
) {
  const period = input.value as
    | { start: YYYYMMDDDateString; end: YYYYMMDDDateString }
    | { start: YYYYMMDateString; end: YYYYMMDateString };

  if (!period.start || !period.end) {
    return null;
  }

  let dateRange: DateRange;

  if (YYYYMMDDDateRegex.test(period.start)) {
    // YYYY-MM-DD
    if (!YYYYMMDDDateRegex.test(period.end) || period.start !== period.end) {
      return null;
    }

    dateRange = {
      start: startOfDay(new Date(period.start)),
      end: endOfDay(new Date(period.end)),
    };
  } else if (YYYYMMDateRegex.test(period.start)) {
    // YYYY-MM
    if (!YYYYMMDateRegex.test(period.end)) {
      return null;
    }

    dateRange = {
      start: startOfMonth(new Date(period.start)),
      end: endOfMonth(new Date(period.end)),
    };
  } else {
    return null;
  }

  if (dateRange.start.getTime() > dateRange.end.getTime()) {
    return null;
  }

  return {
    start: convertToYYYYMMDD(dateRange.start),
    end: convertToYYYYMMDD(dateRange.end),
  };
}

export const parseDateToMonthsTransform = ({ value }: { value: Date }) => {
  const yyyy = value.getFullYear();
  const mm = value.getMonth() + 1;
  return new Date(`${yyyy}-${mm}`);
};

export function parseArrayTransform({ value }: { value: unknown }) {
  return typeof value === "string" ? value.split(",") : value;
}
