import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { cloneDeep, isEqual } from "lodash";
import {
  getMe,
  logout,
  selectUserAndToken,
} from "../../store/slices/authSlice";
import * as urls from "../../constants/urls";
import { useAppDispatch } from "../../store/hooks";

const ProtectedRoute = () => {
  const { user, accessToken } = useSelector(selectUserAndToken);
  const dispatch = useAppDispatch();
  const [currentSession, setCurrentSession] = useState<any>(null);

  const forceLogout = useCallback(() => {
    if (accessToken) {
      dispatch(getMe(accessToken));
    } else {
      dispatch(logout());
    }
  }, [accessToken, dispatch]);

  const handle = useCallback(async () => {
    try {
      const session = await Auth.currentSession();
      if (!isEqual(session, currentSession)) {
        setCurrentSession(cloneDeep(session));
      }
      const timeout =
        session.getAccessToken().getExpiration() * 1000 - new Date().getTime();
      if (timeout && timeout > 10 * 1000) {
        setTimeout(async () => {
          if (session.getRefreshToken().getToken()) {
            // try to refresh token
            const newSession = await Auth.currentSession();
            setTimeout(() => setCurrentSession(cloneDeep(newSession)), 100);
          } else {
            forceLogout();
          }
          // dispatch logout and redirect to login
        }, Math.max(0, timeout - 30 * 1000));
      } else {
        forceLogout();
      }
    } catch (e) {
      forceLogout();
    }
  }, [currentSession, forceLogout]);

  useEffect(() => {
    handle();
  }, [handle]);

  return user ? <Outlet /> : <Navigate to={urls.LOGIN_PATH} replace />;
};

export default ProtectedRoute;
