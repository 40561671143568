import currencyCodeDecimalDigits from "./currency-decimal-digits.constants";
import type { SupportedCurrencyCode } from "./enums";
import { supportedCurrencyCodes } from "./enums";

export const FIXED_CURRENCIES = ["USD", "EUR", "GBP"] as const;

export type FixedCurrencyCode = (typeof FIXED_CURRENCIES)[number];

/**
 * Helper function to replace FIXED_CURRENCIES.includes().
 *
 * As FIXED_CURRENCIES is a read-only array of type USD | EUR | GBP,
 * TS says you're being silly if you try and do FIXED_CURRENCIES.includes()
 * on something that's more general (such as a general string).
 *
 * We want to widen the set of possible parameters to all strings, so we
 * adjust the includes() function on the FIXED_CURRENCIES array.
 *
 * For more info... https://fettblog.eu/typescript-array-includes/
 */
export function isFixedCurrency(
  currency: string,
): currency is FixedCurrencyCode {
  return FIXED_CURRENCIES.includes(currency as FixedCurrencyCode);
}

export function isSupportedCurrency(
  code: string,
): code is SupportedCurrencyCode {
  return (supportedCurrencyCodes as readonly string[]).includes(code);
}

export const currencyDecimalPlaces = currencyCodeDecimalDigits;
