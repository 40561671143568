/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/alt-text */
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container } from "reactstrap";
import { Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import { FormattedDate } from "react-intl";
import { rutterPlatformEnums } from "@s2z-platform/types";
import { ListUsersDto } from "@s2z-platform/dto";
import { accountTypes } from "@s2z-platform/constants";
import { ImpactIntentEntity } from "@s2z-platform/entity";
import useTitle from "../../hooks/useTitle";
import { ProfileType } from "../../types/User";
import "./userList.scss";
import { useGetProductsQuery, useGetUsersQuery } from "../../services/userApi";
import { useAddAdminClaimMutation } from "../../services/claimApi";
import { UserModal } from "./UserModal";
import { enqueueErrorMessage } from "../../store/slices/appSlice";
import { useAppDispatch } from "../../store/hooks";

export type UserListProps = {
  profileType?: ProfileType;
};

const PAGINATION_DROPDOWN_OPTIONS = [10, 25, 50, 100];

/** Users lists */
const UserList = () => {
  // Page title
  const { intlTitle } = useTitle();

  const dispatch = useAppDispatch();

  const [showFilters, setShowFilters] = useState(false);

  const [userInfoUuid, setUserInfoUuid] = useState<string | undefined>(
    undefined
  );

  const [showInfoModal, setShowInfoModal] = useState(false);

  const [filters, setFilters] = useState<ListUsersDto>({
    offset: 0,
    limit: 10,
  });

  const { data: products } = useGetProductsQuery(null);

  const {
    data: users,
    isFetching: usersFetching,
    error: userFetchError,
  } = useGetUsersQuery(filters);

  const [addAdminClaim] = useAddAdminClaimMutation();

  useEffect(() => {
    if (userFetchError) {
      dispatch(
        enqueueErrorMessage(
          "data" in userFetchError
            ? (userFetchError.data as any).message
            : userFetchError
        )
      );
    }
  }, [userFetchError, dispatch]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{intlTitle("dashboard.users.user_list_title")}</title>
      </Helmet>
      <UserModal
        uuid={userInfoUuid}
        show={showInfoModal}
        onClose={() => {
          setShowInfoModal(false);
        }}
      />
      <div className="dashboard-wrapper tabs-contained">
        <Container fluid id="list-container">
          <h1>Users</h1>
          <div className="filters-row">
            <div className="filter-box">
              <div className="filter-options">
                {accountTypes.map((accountType) => (
                  <button
                    type="button"
                    key={accountType}
                    className={
                      filters.accountType === accountType ? "active" : ""
                    }
                    onClick={() => {
                      setFilters({
                        ...filters,
                        accountType:
                          filters.accountType === accountType
                            ? undefined
                            : accountType,
                        offset: 0,
                      });
                    }}
                  >
                    {`${accountType.charAt(0).toUpperCase()}${accountType.slice(
                      1
                    )}`}
                  </button>
                ))}
              </div>
            </div>
            <div className="filter-box">
              <button
                type="button"
                onClick={() => {
                  setShowFilters(!showFilters);
                }}
              >{`${showFilters ? "Hide" : "Show"} filters`}</button>
            </div>
            <div className="filter-box">
              {users && !usersFetching ? (
                `Showing ${filters.offset + 1}-${
                  filters.limit + filters.offset
                } of ${users.count} results`
              ) : (
                <Spinner size="sm" />
              )}
            </div>
            <div className="spacer" />
            <div className="filter-box">
              <div className="filter-options">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setFilters({
                      ...filters,
                      searchQuery: (
                        e.target as unknown as { value: string }[]
                      )[0].value,
                      offset: 0,
                    });
                  }}
                >
                  <input type="text" />
                  <button type="submit">Search</button>
                </form>
              </div>
            </div>
          </div>
          {showFilters && (
            <div className="filters-row">
              <div className="filter-box allow-overflow">
                <div>
                  <h5>Products</h5>
                </div>
                <div className="filter-options">
                  {products ? (
                    products.map((product) => (
                      <button
                        type="button"
                        key={product.identifier}
                        className={
                          filters.productIdentifiers?.includes(
                            product.identifier
                          )
                            ? "active"
                            : ""
                        }
                        onClick={() => {
                          setFilters({
                            ...filters,
                            // ordinarily I'd agree with you ESLint but now is not the time
                            // eslint-disable-next-line no-nested-ternary
                            productIdentifiers: filters.productIdentifiers
                              ? filters.productIdentifiers.includes(
                                  product.identifier
                                )
                                ? filters.productIdentifiers.filter(
                                    (i) => i !== product.identifier
                                  )
                                : [
                                    ...filters.productIdentifiers,
                                    product.identifier,
                                  ]
                              : [product.identifier],
                            offset: 0,
                          });
                        }}
                      >
                        {product.name}
                      </button>
                    ))
                  ) : (
                    <Spinner />
                  )}
                </div>
                <div>
                  <h5>Store platforms</h5>
                </div>
                <div className="filter-options">
                  {rutterPlatformEnums.map((name) => (
                    <button
                      type="button"
                      key={name}
                      className={
                        filters.storePlatforms?.includes(name) ? "active" : ""
                      }
                      onClick={() => {
                        setFilters({
                          ...filters,
                          // ordinarily I'd agree with you ESLint but now is not the time
                          // eslint-disable-next-line no-nested-ternary
                          storePlatforms: filters.storePlatforms
                            ? filters.storePlatforms.includes(name)
                              ? filters.storePlatforms.filter((i) => i !== name)
                              : [...filters.storePlatforms, name]
                            : [name],
                          offset: 0,
                        });
                      }}
                    >
                      {name}
                    </button>
                  ))}
                </div>
                <div className="filter-options">
                  <div>
                    Has estimate(s):{" "}
                    <button
                      type="button"
                      className={filters.hasEstimates === true ? "active" : ""}
                      onClick={() => {
                        setFilters({
                          ...filters,
                          hasEstimates:
                            filters.hasEstimates === true ? undefined : true,
                          offset: 0,
                        });
                      }}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className={filters.hasEstimates === false ? "active" : ""}
                      onClick={() => {
                        setFilters({
                          ...filters,
                          hasEstimates:
                            filters.hasEstimates === false ? undefined : false,
                          offset: 0,
                        });
                      }}
                    >
                      No
                    </button>
                  </div>
                  <div>
                    Has vehicle(s):{" "}
                    <button
                      type="button"
                      className={filters.hasVehicles === true ? "active" : ""}
                      onClick={() => {
                        setFilters({
                          ...filters,
                          hasVehicles:
                            filters.hasVehicles === true ? undefined : true,
                          offset: 0,
                        });
                      }}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className={filters.hasVehicles === false ? "active" : ""}
                      onClick={() => {
                        setFilters({
                          ...filters,
                          hasVehicles:
                            filters.hasVehicles === false ? undefined : false,
                          offset: 0,
                        });
                      }}
                    >
                      No
                    </button>
                  </div>
                  <div>
                    Has widget(s):{" "}
                    <button
                      type="button"
                      className={filters.hasWidgets === true ? "active" : ""}
                      onClick={() => {
                        setFilters({
                          ...filters,
                          hasWidgets:
                            filters.hasWidgets === true ? undefined : true,
                          offset: 0,
                        });
                      }}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className={filters.hasWidgets === false ? "active" : ""}
                      onClick={() => {
                        setFilters({
                          ...filters,
                          hasWidgets:
                            filters.hasWidgets === false ? undefined : false,
                          offset: 0,
                        });
                      }}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!users ? (
            <div id="no-results">
              <Spinner />
            </div>
          ) : (
            <table id="user-results">
              <tbody>
                {users.items.map((u) => (
                  <tr className="user-result" key={u.uuid}>
                    <td>
                      <div className="name">
                        {`${u.firstname ?? "-"} ${u.lastname ?? "-"}${
                          u.userAccountRefs.some(
                            (r) => r.account.accountType === "business"
                          )
                            ? ` (${u.userAccountRefs
                                .filter(
                                  (r) => r.account.accountType === "business"
                                )
                                .map(
                                  (r) => r.account.businessDetails?.companyName
                                )
                                .join(", ")})`
                            : ""
                        }`}
                      </div>
                      <div className="email">{u.email}</div>
                      <div className="reg-date">
                        {u.userAccountRefs &&
                          u.userAccountRefs.length > 0 &&
                          [
                            ...u.userAccountRefs.map(
                              (r) => r.account.accountType
                            ),
                            "",
                          ].join(", ")}
                        <FormattedDate value={u.createdAt} />
                      </div>
                    </td>
                    <td>
                      {u.userAccountRefs
                        .reduce<ImpactIntentEntity[]>(
                          (acc, cur) => [...acc, ...cur.account.impactIntents],
                          []
                        )
                        .filter(
                          (i) =>
                            (i.interval === "one_time" ||
                              i.subscriptionStatus === "active") &&
                            i.intentStatus === "succeeded"
                        )
                        .reduce<
                          {
                            name: string;
                            identifier: string;
                            quantity: number;
                          }[]
                        >((acc, i) => {
                          i.customerProductConfigs.forEach((c) => {
                            const existingEntry = acc.find(
                              (a) => a.identifier === c.product.identifier
                            );

                            if (existingEntry) {
                              existingEntry.quantity += 1;
                            } else {
                              acc.push({
                                name:
                                  c.product.identifier ===
                                  "business-ecommerce-recurring"
                                    ? `${c.product.name} (${
                                        c.store ? c.store.platform : "no store"
                                      })`
                                    : c.product.name,
                                identifier: c.product.identifier,
                                quantity: 1,
                              });
                            }
                          });

                          return acc;
                        }, [])
                        .map((e) => (
                          <div className="" key={e.identifier}>
                            {`${e.name} x${e.quantity}`}
                          </div>
                        ))}
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn-nostyle btn-icon"
                        onClick={() => {
                          setUserInfoUuid(u.uuid);
                          setShowInfoModal(true);
                        }}
                      >
                        <img src="/images/info.svg" height={20} width={20} />
                      </button>
                    </td>
                    <td>
                      <button
                        type="button"
                        title="Impersonate"
                        className="btn-nostyle btn-icon"
                        onClick={async () => {
                          await addAdminClaim({
                            body: {
                              impersonate: u.id,
                            },
                          });

                          window.open(
                            process.env.REACT_APP_IMPERSONATE_URL,
                            "_blank"
                          );
                        }}
                      >
                        <img
                          src="/images/impersonate.svg"
                          width={20}
                          height={20}
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <div id="pagination">
            <div>
              Show{" "}
              <select
                onChange={(v) => {
                  setFilters({
                    ...filters,
                    limit: parseInt(v.target.value, 10),
                    offset: 0,
                  });
                }}
                value={filters.limit}
              >
                {PAGINATION_DROPDOWN_OPTIONS.map((o) => (
                  <option key={o} value={o}>
                    {o}
                  </option>
                ))}
              </select>{" "}
              users
            </div>

            {users && (
              <div>
                Page{" "}
                <select
                  onChange={(v) => {
                    setFilters({
                      ...filters,
                      offset: parseInt(v.target.value, 10) * filters.limit,
                    });
                  }}
                  value={Math.floor(filters.offset / filters.limit)}
                >
                  {[...Array(Math.ceil(users.count / filters.limit))].map(
                    (_, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <option key={i} value={i}>
                        {i + 1}
                      </option>
                    )
                  )}
                </select>
              </div>
            )}
          </div>
        </Container>
      </div>
    </HelmetProvider>
  );
};

export default UserList;
