import { mkenum } from "../../utils/mkenum";

export const CalculatorCountry = mkenum({
  AL: "AL",
  AR: "AR",
  AU: "AU",
  AT: "AT",
  BE: "BE",
  BG: "BG",
  BR: "BR",
  CA: "CA",
  CH: "CH",
  CL: "CL",
  CN: "CN",
  CM: "CM",
  CO: "CO",
  CR: "CR",
  CY: "CY",
  CZ: "CZ",
  DE: "DE",
  DK: "DK",
  ES: "ES",
  EE: "EE",
  FI: "FI",
  FR: "FR",
  GB: "GB",
  GE: "GE",
  GR: "GR",
  HK: "HK",
  HR: "HR",
  HU: "HU",
  ID: "ID",
  IN: "IN",
  IE: "IE",
  IS: "IS",
  IL: "IL",
  IT: "IT",
  JP: "JP",
  KR: "KR",
  LT: "LT",
  LU: "LU",
  LV: "LV",
  MA: "MA",
  MG: "MG",
  MX: "MX",
  MK: "MK",
  MT: "MT",
  NL: "NL",
  NO: "NO",
  NZ: "NZ",
  PL: "PL",
  PT: "PT",
  RO: "RO",
  RU: "RU",
  SA: "SA",
  SN: "SN",
  SG: "SG",
  RS: "RS",
  SK: "SK",
  SI: "SI",
  SE: "SE",
  TR: "TR",
  US: "US",
  ZA: "ZA",
  ZM: "ZM",
});

export type CalculatorCountry =
  (typeof CalculatorCountry)[keyof typeof CalculatorCountry];

export const CALCULATOR_COUNTRIES = Object.keys(
  CalculatorCountry,
) as CalculatorCountry[];
