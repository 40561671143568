export class InstantProductConfigTypeJSON {
  period?: {
    start: Date;
    end: Date;
  };
  offsetFootprint?: boolean;
  // BANANA - We've called 2 things userMetadata, call this one transactionMetadata or similar
  // userMetadata?: UserMetadata;
  userMetadata?: Record<string, unknown>;
  singleDate?: Date;
  hideCost?: boolean;
  theme?: string;
}

export class TransactionProductConfigJSON extends InstantProductConfigTypeJSON {}
