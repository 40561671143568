import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { removeMessage, selectMessages } from "../../../store/slices/appSlice";
import ToastMessages, {
  ToastMessage,
} from "../../Common/feedback/ToastMessages";

const AppToastMessages = () => {
  const messages: ToastMessage[] = useAppSelector(selectMessages);
  const dispatch = useAppDispatch();

  const handleClose = (message: ToastMessage) => {
    dispatch(removeMessage(message));
  };

  return (
    <ToastMessages
      messages={messages}
      onClose={handleClose}
      autoCloseDelai={5000}
      toastContainerProps={{
        style: { zIndex: 1 },
      }}
    />
  );
};

export default AppToastMessages;
