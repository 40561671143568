import { mkenum } from "../../utils/mkenum";

export const S2ZSubscriptionStatus = mkenum({
  pending: "pending",
  "3ds_pending": "3ds_pending",
  failed: "failed",
  active: "active",
  canceled: "canceled",
  declined: "declined",
  expired: "expired",
  frozen: "frozen",
});

export type S2ZSubscriptionStatus =
  (typeof S2ZSubscriptionStatus)[keyof typeof S2ZSubscriptionStatus];

export const S2ZSubscriptionStatuses = Object.values(
  S2ZSubscriptionStatus,
) as S2ZSubscriptionStatus[];
