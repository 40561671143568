import { mkenum } from "../utils/mkenum";

export const ProductName = mkenum({
  whole_tree: "whole_tree",
  partial_tree: "partial_tree",
  "100_kg_co2e": "100_kg_co2e",
  "50_kg_co2e": "50_kg_co2e",
  "100_g_plastic": "100_g_plastic",
});

export type ProductName = (typeof ProductName)[keyof typeof ProductName];

export const productNames = Object.values(ProductName) as ProductName[];
