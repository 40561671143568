/**
 * Options can be found at https://docs.rutterapi.com/docs/supported-commerce-platforms
 * */
export const rutterPlatformEnums = [
  "AMAZON",
  "BIG_COMMERCE",
  "EBAY",
  "ETSY",
  "FNAC",
  "GUMROAD",
  "LAZADA",
  "MAGENTO",
  "MERCADO_LIBRE",
  "PRESTASHOP",
  "SHOPER",
  "SHOPEE",
  "SHOPIFY",
  "SQUARE",
  "SQUARESPACE",
  "WALMART",
  "WIX",
  "WOO_COMMERCE",
] as const;

export type RutterPlatformEnumType = (typeof rutterPlatformEnums)[number];
