import { useState, useMemo } from "react";
import Typography from "@material-ui/core/Typography";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import clsx from "clsx";
import Avatar from "../Common/Avatar";
import * as urls from "../../constants/urls";
import { useAppSelector } from "../../store/hooks";
import { selectUserAndToken } from "../../store/slices/authSlice";
import useGoogleAuth from "../../hooks/auth/useGoogleAuth";
import { RootState } from "../../store/store";

/* Navbar Avatar menu */
const AvatarMenu = ({ className }: any) => {
  // dropdown opened state
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const userToken = useSelector((state: RootState) => state.auth.accessToken);

  // dropdown open state toggle
  const toggle = () => setDropdownOpen(!dropdownOpen);

  const { user } = useAppSelector(selectUserAndToken);
  const initials = useMemo(() => {
    if (!user) {
      return "";
    }
    const { firstname, lastname } = user;
    return [firstname, lastname].map((v) => (v ? v.substr(0, 1) : "")).join("");
  }, [user]);

  const { logout: logoutFn } = useGoogleAuth({
    redirectSignIn: window.location.origin + urls.LOGIN_PATH,
    redirectSignOut: window.location.origin + urls.LOGIN_PATH,
  });

  const handleLogout = async () => {
    await logoutFn();
  };

  return (
    <Dropdown
      className={clsx("avatar-menu", className)}
      isOpen={dropdownOpen}
      toggle={toggle}
    >
      <DropdownToggle tag="div" caret>
        <Avatar
          alt={initials && initials.toUpperCase()}
          src=""
          color="blue"
          size="xs"
        />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem tag="div" className="auth-info">
          <Typography variant="h5" component="p" className="user-name">
            {user?.firstname} {user?.lastname}
          </Typography>
          <Typography variant="subtitle1">{user?.email}</Typography>
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            if (navigator.clipboard && userToken) {
              navigator.clipboard.writeText(userToken);
            }
          }}
        >
          <span className="label">Copy admin JWT</span>
        </DropdownItem>
        <DropdownItem onClick={handleLogout}>
          <span className="label">
            <FormattedMessage id="actions.logout" />
          </span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default AvatarMenu;
