import useOAuth, { UseOAuthArgs } from "./useOAuth";

const useGoogleAuth = (props: Omit<UseOAuthArgs, "provider">) => {
  const { redirectSignIn, redirectSignOut } = props;

  return useOAuth({
    redirectSignIn,
    redirectSignOut,
    provider: "google",
  });
};

export default useGoogleAuth;
