import { Routes, Route, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import * as urls from "../../constants/urls";
import Login from "../Login/Login";
import NotFound from "../NotFound/404";
// import UserList from "../Users/UserList";
import Home from "../Home";
import ProtectedRoute from "./ProtectedRoute";
import UserList from "../Users/UserList";

const Redirect = ({ to }: { to: string }) => {
  const navigate = useNavigate();

  useEffect(() => navigate(to), [to]);

  return <span>Redire</span>;
  // return null;
};

const AppRoutes = () => (
  <Routes>
    <Route path="" element={<Redirect to={urls.USER_LIST_PATH} />} />
    {/* Login */}
    <Route path={urls.LOGIN_PATH} element={<Login />} />
    <Route element={<ProtectedRoute />}>
      {/* Home */}
      <Route path={urls.HOME_PAGE} element={<Home />} />

      {/* Business List */}
      <Route path={`${urls.USER_LIST_PATH}`} element={<UserList />} />

      {/* catch all, redirect unknown routes to login */}
      <Route path="*" element={<NotFound />} />
    </Route>
  </Routes>
);

export default AppRoutes;
