import { milesToKmConversion } from "@s2z-platform/constants";
import type {
  MileageRecord,
  PublicMileageRecord,
  PublicUserVehicleRecord,
  PublicVehicleAccount,
} from "@s2z-platform/types";

// NOTE - not used
// export function sanitiseVehicleData(
//   entity:
//     | VehicleDataUkGeneralEntity
//     | VehicleDataUkHgvEntity
//     | VehicleDataUkLookupEntity
//     | VehicleDataUsGeneralEntity
//     | VehicleDataUsLookupEntity,
//   productionYear?: number
// ) {
//   if ("refrigerated" in entity) {
//     if (entity.vehicleType === "hgv") {
//       return sanitiseUkHgvEntity(entity);
//     }

//     if ((entity as VehicleDataUkLookupEntity).make) {
//       return sanitiseUkLookupEntity(
//         entity as VehicleDataUkLookupEntity,
//         productionYear as number
//       );
//     }

//     return sanitiseUkGeneralEntity(entity as VehicleDataUkGeneralEntity);
//   }
//   if (entity.market.toLowerCase() === "us") {
//     if ((entity as VehicleDataUsLookupEntity).make) {
//       return sanitiseUsLookupEntity(
//         entity as VehicleDataUsLookupEntity,
//         productionYear as number
//       );
//     }
//     return sanitiseUsGeneralEntity(entity as VehicleDataUsGeneralEntity);
//   }
// }

export function convertMileage(
  fromUnit: "miles" | "km",
  toUnit: "miles" | "km",
  quantity: number,
) {
  if (fromUnit === "miles" && toUnit === "km") {
    return quantity * milesToKmConversion;
  }
  if (fromUnit === "km" && toUnit === "miles") {
    return quantity / milesToKmConversion;
  }
  if (fromUnit === toUnit) {
    return quantity;
  }
  throw new Error(
    `Unrecognised units provided! Should be "miles" | "km", received "${fromUnit}" | "${toUnit}" `,
  );
}

export function getOffsetMileageFromRecords(
  mileageRecords: PublicMileageRecord[] | MileageRecord[],
  unit: "miles" | "km",
) {
  let totalMileage = 0;

  const offsetMileageRecords = mileageRecords.filter((r) =>
    "status" in r
      ? r.status === "paid"
      : r.impactIntent?.payments?.some(
          (p: { status: string }) => p.status === "paid",
        ),
  );

  offsetMileageRecords.forEach((r) => {
    if (r?.units === unit) {
      totalMileage += Number(r.quantity);
    } else {
      totalMileage += convertMileage(r?.units, unit, Number(r?.quantity));
    }
  });

  return totalMileage;
}

export const getAccountTotalMileageOffset = (account: PublicVehicleAccount) => {
  let total = 0;
  account?.vehicles.forEach((v: PublicUserVehicleRecord) => {
    total += getOffsetMileageFromRecords(
      v.mileageRecords,
      account.vehicles[0].annualMileageUnit ?? "km",
    );
  });
  return total;
};

/**
 *
 * @returns Number of Kg of Co2 put out by a vehicle per year of driving, based on it's emissions rating and annual mileage
 */
export function getAnnualCo2eKg(
  annualMileage: number,
  annualMileageUnit: "km" | "miles",
  gCo2ePerKm: number,
) {
  const annualMileageKm = convertMileage(
    annualMileageUnit,
    "km",
    annualMileage,
  );

  return (annualMileageKm * gCo2ePerKm) / 1000;
}
