/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Menu } from "../../types/Menu";
import { RootState } from "../store";

type MenuState = {
  menus: Menu[];
};

/**
 * Default state object with initial values.
 */
const initialState: MenuState = {
  menus: [],
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setMenus: (state, action: PayloadAction<Menu[]>) => {
      state.menus = action.payload;
    },
    clearMenu: (state) => {
      state.menus = [];
    },
  },
});

export const { setMenus, clearMenu } = menuSlice.actions;

export const selectMenus = (state: RootState) => state.menu.menus;

export default menuSlice.reducer;
