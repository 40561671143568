import { Modal, Spinner } from "react-bootstrap";
import { useAppSelector } from "../../../store/hooks";
import { selectShowProgressDialogCount } from "../../../store/slices/appSlice";

const AppProgressDialog = () => {
  const showProgressDialogCount = useAppSelector(selectShowProgressDialogCount);
  const show = showProgressDialogCount > 0;

  return (
    <Modal
      show={show}
      onHide={() => {}}
      backdrop="static"
      keyboard={false}
      className="progress-modal"
      dialogClassName="progress-dialog"
      backdropClassName="progress-backdrop"
      centered
    >
      <Spinner animation="border" variant="primary" />
    </Modal>
  );
};

export default AppProgressDialog;
