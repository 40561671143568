import { mkenum } from "../../utils/mkenum";

export const SubscriptionPlanType = mkenum({
  family: "family",
  individual: "individual",
  business: "business",
  plugin: "plugin",
});

export type SubscriptionPlanType =
  (typeof SubscriptionPlanType)[keyof typeof SubscriptionPlanType];

export const subscriptionPlanTypes = Object.values(
  SubscriptionPlanType,
) as SubscriptionPlanType[];
