import { mkenum } from "../utils/mkenum";

export const CertificateTheme = mkenum({
  standard: "standard",
  christmas2023: "christmas2023",
  giftJan2024: "giftJan2024",
});

export type CertificateTheme =
  (typeof CertificateTheme)[keyof typeof CertificateTheme];

export const certificateThemes = Object.values(
  CertificateTheme,
) as CertificateTheme[];
