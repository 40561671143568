import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import { NavLink, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import { NavbarBrand } from "reactstrap";
import { createStyles, Theme, withWidth } from "@material-ui/core";
import { Image } from "react-bootstrap";
import Button from "../Common/Button";
import * as urls from "../../constants/urls";
import AvatarMenu from "./AvatarMenu";
// import useSidebarMenu from '../../hooks/useSidebarMenu';
import { IMG_BASE_URL } from "../../constants/urls";

// Drawer styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "70%",
      maxWidth: 290,
      backgroundColor: theme.palette.primary.dark,
      padding: "25px 20px 30px 16px",
    },
    list: {
      flex: 1,
      paddingTop: 45,
    },
    listItem: {
      padding: "6px 0",

      "&.active": {
        backgroundColor: theme.palette.background.paper,
        marginLeft: -16,
        padding: "8px 0",
        borderTopRightRadius: 100,
        borderBottomRightRadius: 100,
        "& .nav-link": {
          paddingLeft: 18,
        },
      },
      "& .nav-link": {
        padding: "0 3px",
        "&.active": {
          color: theme.palette.primary.dark,
        },
        "&:hover": {
          textDecoration: "none !important",
        },
      },
    },
  })
);

/**
 * Drawer components with control button
 */
const MobileDrawer = (props: any) => {
  const { width } = props;
  const { pathname } = useLocation();
  // const {menuItems} = useSidebarMenu();

  // Drawer opened state
  const [opened, setOpened] = useState(false);
  const [checkPathName, setCheckPathName] = useState(false);
  // view data toggler state
  const [viewDataOn, setViewDataOn] = useState(true);
  // view data toggler callback
  const toggleViewData = () => setViewDataOn(!viewDataOn);

  const classes = useStyles();

  // TODO: get from useSidebarMenu
  const menuItems = [
    {
      path: urls.HOME_PAGE,
      name: "home",
    },
    {
      path: urls.USER_LIST_PATH,
      name: "users",
    },
  ];

  useEffect(() => {
    // Not list path ?
    setCheckPathName(
      document.querySelectorAll(".top-block.bg-white").length > 0
    );
  }, [pathname, width]);

  // Drawer opened state toggler
  const toggleDrawer = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (
      event.type === "keydown"
      // (event?.key === "Tab" || event?.key === "Shift")
    ) {
      return;
    }
    setOpened(!opened);
  };

  // Check is the menu is active
  const isActive = (item: any) =>
    item.path === urls.HOME_PAGE
      ? pathname === urls.HOME_PAGE
      : pathname.includes(item.path);

  return (
    <>
      {/* Drawer toggler */}
      <div
        id="mobileHeader"
        className={clsx(
          "d-flex align-items-center justify-content-between",
          checkPathName && "bg-white"
        )}
      >
        <Button menu title="" className="d-lg-none" onClick={toggleDrawer} />
        <div className="d-lg-none pt-3 pe-s2z-17">
          <AvatarMenu
            className="mobile"
            viewDataOn={viewDataOn}
            viewDataChange={toggleViewData}
          />
        </div>
      </div>
      {/* Drawer component (shows logo and admin part urls) */}
      <Drawer
        classes={{
          paper: classes.paper,
        }}
        className="d-lg-none"
        open={opened}
        onClose={toggleDrawer}
        elevation={0}
      >
        <NavbarBrand
          href={urls.HOME_PAGE}
          onClick={(e) => e.stopPropagation()}
          className="vtl-brand"
        >
          <Image
            src={`${IMG_BASE_URL}S2Z Logo.svg`}
            alt=""
            width={71}
            height={30}
          />
        </NavbarBrand>
        <div className={classes.list}>
          {menuItems.map((item, index: number) => (
            <div
              className={clsx(classes.listItem, isActive(item) && "active")}
              // eslint-disable-next-line react/no-array-index-key
              key={`menu-item_${index}`}
            >
              <NavLink
                className={clsx(
                  "nav-link text-decoration-none",
                  isActive(item) && "active"
                )}
                to={item.path}
                onClick={() => setOpened(false)}
              >
                <div className="d-flex align-items-center">
                  <span className="icon-wrapper">
                    <i className={clsx("menu-icon", item.name)} />
                  </span>
                  <FormattedMessage id={`dashboard.menu.${item.name}`} />
                </div>
              </NavLink>
            </div>
          ))}
        </div>
      </Drawer>
    </>
  );
};

export default withWidth()(MobileDrawer);
