import { mkenum } from "../../utils/mkenum";

export const SubscriptionType = mkenum({
  platform: "platform",
  plugin: "plugin",
  bespoke: "bespoke",
});

export type SubscriptionType =
  (typeof SubscriptionType)[keyof typeof SubscriptionType];

export const subscriptionTypes = Object.values(
  SubscriptionType,
) as SubscriptionType[];
