import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

import listenerMiddlewares from "./listenerMiddleware";
import { exampleApi } from "./queries/exampleApi";
import appSlice from "./slices/appSlice";
import authSlice from "./slices/authSlice";
import menuSlice from "./slices/menuSlice";
import { userApi } from "../services/userApi";
import { claimApi } from "../services/claimApi";

const rootReducer = combineReducers({
  app: appSlice,
  auth: authSlice,
  menu: menuSlice,
  [exampleApi.reducerPath]: exampleApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [claimApi.reducerPath]: claimApi.reducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      ...listenerMiddlewares.map((lm) => lm.middleware),
      exampleApi.middleware,
      userApi.middleware,
      claimApi.middleware,
    ]),
  devTools: process.env.NODE_ENV !== "production",
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

export default store;
