import { mkenum } from "../utils/mkenum";

export const ProductTag = mkenum({
  WholeTree: "whole_tree",
  PartialTree: "partial_tree",
  Co2e100Kg: "100_kg_co2e",
  Co2e50kg: "50_kg_co2e",
  Plastic100g: "100_g_plastic",
});

export type ProductTag = (typeof ProductTag)[keyof typeof ProductTag];

export const productTags = Object.values(ProductTag) as ProductTag[];
