import { useEffect } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useLocation } from "react-router-dom";
import { get } from "lodash";
import MenuLeft from "./components/Navbar/MenuLeft";
import MobileDrawer from "./components/Navbar/MobileDrawer";
import AppRoutes from "./components/Routes/Routes";
import theme from "./constants/theme";
import * as urls from "./constants/urls";
import AppToastMessages from "./components/global/feedback/AppToastMessages";
import AppProgressDialog from "./components/global/feedback/AppProgressDialog";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { getMe, selectUserAndToken } from "./store/slices/authSlice";

const App = () => {
  const location = useLocation();
  const { accessToken } = useAppSelector(selectUserAndToken);
  const dispatch = useAppDispatch();
  const rehydrated = useAppSelector((rootState) =>
    get(rootState, "_persist.rehydrated", false)
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (accessToken) {
        dispatch(getMe(accessToken));
      }
    }, 100);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!rehydrated) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <AppToastMessages />
        <AppProgressDialog />
        <section className="react-container d-flex flex-column flex-lg-row">
          {location.pathname !== urls.LOGIN_PATH && (
            <>
              <MenuLeft />
              <MobileDrawer />
            </>
          )}
          <AppRoutes />
        </section>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
