import type {
  WidgetConfigBackgroundMode,
  WidgetConfigIconSize,
  WidgetConfigIconStyle,
  WidgetConfigTextColor,
} from "@s2z-platform/constants";

export class WidgetStylingConfigJSON {
  iconSize: WidgetConfigIconSize;
  iconStyle: WidgetConfigIconStyle;
  backgroundMode: WidgetConfigBackgroundMode;
  textColor: WidgetConfigTextColor | null;
  backgroundImageId: number | null;
}
