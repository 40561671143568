import {
  addMonths,
  differenceInMonths,
  endOfMonth,
  isAfter,
  isBefore,
  startOfMonth,
} from "date-fns";
import BigNumber from "bignumber.js";
import {
  currencyDecimalPlaces,
  milesToKmConversion,
} from "@s2z-platform/constants";
import type {
  SubscriptionType,
  SupportedCurrencyCode,
  AccountType,
} from "@s2z-platform/constants";
import type {
  DateRange,
  DisplayUnitsNumber,
  ISO8601DateString,
  WorkingUnitsNumber,
  YYYYMMDDDateString,
  YYYYMMDateString,
} from "@s2z-platform/types";

// BANANA: EPIC CW TIMES RESULTED IN THIS ESLINT-DISABLE BEING ADDED.
// IT APPLIES TO EVERYTHING BELOW, AND I DON'T HAVE TIME
// TO FIX THE CONSEQUENCES RIGHT NOW.
/* eslint-disable */
/**
 * Update object with given data
 */
export function updateObject(object: any, data: any, skipUndefined = true) {
  if (!object || !data) {
    return object;
  }

  if (
    typeof object !== "object" ||
    typeof data !== "object" ||
    Object.keys(data).length === 0
  ) {
    return object;
  }

  for (const key of Object.keys(data)) {
    // Skip id
    if (key !== "id") {
      if (!skipUndefined) {
        object[key] = data[key];
      } else {
        // Apply only if not undefined
        if (data[key] !== undefined) {
          object[key] = data[key];
        }
      }
    }
  }

  return object;
}

/**
 * Needed because Stripe doesn't offer an amountForDisplay or any distinguisher between 0 and 2 decimal placed currencies <_< n1 Stripe!
 * @param currency 3 letter currency code (case doesn't matter)
 * @param amount cost in smallest denomination
 * @returns displayable cost eg: ("GBP", 2850) => 28.50
 */
export function workingToDisplayUnits(currency: string, amount: number) {
  const decimalDigits = currency
    ? currencyDecimalPlaces[currency.toUpperCase() as SupportedCurrencyCode]
    : 2;
  return Number(
    (amount / 10 ** decimalDigits).toFixed(decimalDigits),
  ) as DisplayUnitsNumber;
}

/**
 * Returns working units as a string, with any appropriate trailing '0's, for direct display to the user
 */
export function workingToDisplayUnitsAsString(
  currency: string,
  amount: number,
) {
  const decimalDigits =
    currencyDecimalPlaces[currency.toUpperCase() as SupportedCurrencyCode];
  return (amount / 10 ** decimalDigits).toFixed(decimalDigits);
}

export function displayToWorkingUnits(currency: string, amount: number) {
  const decimalDigits = currency
    ? currencyDecimalPlaces[currency.toUpperCase() as SupportedCurrencyCode]
    : 2;
  return Number(
    (amount * 10 ** decimalDigits).toFixed(0),
  ) as WorkingUnitsNumber;
}

/**
 * Convert Stripe timestamp into Date
 *
 * Note: Stripe timestamps are in seconds
 */
export function stripeTimestampToDate(timestamp: number): Date {
  return new Date(timestamp * 1000);
}

export function dateToStripeTimestamp(date: Date): number {
  return Math.floor(date.getTime() / 1000);
}

/** A string of digits only */
export const MobileNumberRegex = /^\d+$/;

/** Looks like 2023-08-18 */
export const YYYYMMDDDateRegex = /^\d\d\d\d-\d\d-\d\d$/;

/** Looks like 2023-08 */
export const YYYYMMDateRegex = /^\d\d\d\d-\d\d$/;

export function getImpactPeriodForCreateDate(date: Date): DateRange {
  return {
    start: startOfMonth(date),
    end: endOfMonth(date),
  };
}

export type UntypedMetadata = { [key: string]: string };

export function getFootprintPercentage(
  subscriptionType: SubscriptionType,
  userProfileType: AccountType,
) {
  if (subscriptionType === "platform" && userProfileType === "business") {
    // If the subscription is earth-positive workforce, it only contributes 20%
    // of its impact to the business' footprint
    return 20;
  }

  // Otherwise, apply all impact
  return 100;
}

/**
 * Returns the number of months (inclusive) between two dates.
 *
 * For example, periodLength(Jan 2023, Mar 2023) = 3.
 */
export function periodLength(start: Date, end: Date) {
  return differenceInMonths(
    startOfMonth(addMonths(end, 1)),
    startOfMonth(start),
  );
}

export function isBetween(
  date: Date,
  start: Date,
  end: Date,
  inclusivity: "inclusive" | "exlusive",
) {
  if (inclusivity === "inclusive") {
    return !isBefore(date, start) && !isAfter(date, end);
  }
  return isAfter(date, start) && isBefore(date, end);
}

export function getProportionOfImpactWithinPeriod(
  impactDateRange: { start: Date; end: Date },
  dateRangeToCompare: { start: Date; end: Date },
  includeUnassigned?: boolean,
) {
  // If all of impact is within specified period, return 1
  if (
    (isBetween(
      impactDateRange.start,
      dateRangeToCompare.start,
      dateRangeToCompare.end,
      "inclusive",
    ) &&
      isBetween(
        impactDateRange.end,
        dateRangeToCompare.start,
        dateRangeToCompare.end,
        "inclusive",
      )) ||
    (!impactDateRange.start && !impactDateRange.end && includeUnassigned)
  ) {
    return { proportion: 1 };
  } else if (
    // If none of specified period is within impact range, return 0
    !isBetween(
      dateRangeToCompare.start,
      impactDateRange.start,
      impactDateRange.end,
      "inclusive",
    ) &&
    !isBetween(
      dateRangeToCompare.end,
      impactDateRange.start,
      impactDateRange.end,
      "inclusive",
    )
  ) {
    return { proportion: 0 };
  } else {
    // If some of specified period is within impact range, calculate proportion
    const numberOfMonthsAtStart = differenceInMonths(
      dateRangeToCompare.start,
      impactDateRange.start,
    );
    const numberOfMonthsAtEnd = differenceInMonths(
      impactDateRange.end,
      dateRangeToCompare.end,
    );
    const numberOfMonthsImpact =
      differenceInMonths(impactDateRange.end, impactDateRange.start) + 1;

    let numberOfMonthsWithinDateRange = 0;

    if (numberOfMonthsAtStart > 0 && numberOfMonthsAtEnd <= 0) {
      console.debug(`Impact ends within date range but starts outside of it`);
      numberOfMonthsWithinDateRange =
        numberOfMonthsImpact - numberOfMonthsAtStart;
    } else if (numberOfMonthsAtEnd > 0 && numberOfMonthsAtStart <= 0) {
      console.debug(`Impact starts within date range but ends outside of it`);
      numberOfMonthsWithinDateRange =
        numberOfMonthsImpact - numberOfMonthsAtEnd;
    } else {
      console.debug(`Impact starts and ends outside of date range`);
      numberOfMonthsWithinDateRange =
        numberOfMonthsImpact - numberOfMonthsAtStart - numberOfMonthsAtEnd;
    }

    return {
      proportion: numberOfMonthsWithinDateRange / numberOfMonthsImpact,
    };
  }
}

/**
 * @param date Date object or ISO8601 date string
 * @returns YYYY-MM string
 */
export const convertToYYYYMM = (date: Date | string) => {
  const inputDate = typeof date === "string" ? new Date(date) : date;
  const month = inputDate.getMonth() + 1;
  const monthString = `${month < 10 ? 0 : ""}${month}`;
  return `${inputDate.getFullYear()}-${monthString}` as YYYYMMDateString;
};

/**
 * @param date Date object or ISO8601 date string
 * @returns YYYY-MM-DD string
 */
export const convertToYYYYMMDD = (date: Date | string) => {
  const inputDate = typeof date === "string" ? new Date(date) : date;
  const day = inputDate.getDate();
  const dayString = `${day < 10 ? 0 : ""}${day}`;
  return `${convertToYYYYMM(date)}-${dayString}` as YYYYMMDDDateString;
};

export const convertYYYYMMToDate = (
  dateString: YYYYMMDDDateString | YYYYMMDateString,
) => {
  const parts = dateString.split("-").map(Number);

  if (parts.length === 2) {
    // YYYY-MM format
    const [year, month] = parts;
    return new Date(year, month - 1, 1);
  }
  if (parts.length === 3) {
    // YYYY-MM-DD format
    const [year, month, day] = parts;
    return new Date(year, month - 1, day);
  }
  throw new Error("Invalid date format. Expected YYYY-MM or YYYY-MM-DD");
};

export function milesToKm(miles: number) {
  return miles * milesToKmConversion;
}

/**
 *
 * @param input string to be capitalised
 * @param capitaliseAfter Array of string symbols to capitalise word after, default: undefined
 * @returns capitalised string or ""
 */
export function capitaliseFirstLetter(
  input: string,
  capitaliseAfter?: (" " | "-")[],
) {
  if (input && (!capitaliseAfter || capitaliseAfter.length < 1))
    return `${input[0].toUpperCase()}${input.slice(1).toLowerCase()}`;

  if (input && capitaliseAfter) {
    let res = input;
    capitaliseAfter.forEach((symbol) => {
      res = res
        .split(symbol)
        .map((string) => {
          if (!string[0]) return string;
          return `${string[0].toUpperCase()}${string.slice(1)}`;
        })
        .join(symbol);
    });
    return res;
  }
  return "";
}

export function genArrayOfYears(
  noOfYears: number,
  lastYear: number = new Date().getFullYear(),
) {
  const arr = [];
  for (let i = 0; i < noOfYears; i += 1) {
    arr.push(lastYear - i);
  }
  return arr;
}

export function genArrayOfYearsSince(
  startYear: number,
  lastYear: number = new Date().getFullYear(),
) {
  return Array.from(
    { length: lastYear - startYear + 1 },
    (_, i) => startYear + i,
  ).sort((a, b) => (a > b ? -1 : 1));
}

/**
 * @description Extracts domains from a string and formats them like "example.com" or "sub.example.com"
 * @example extractDomains("https://example.com/products?id=123") => ["example.com"]
 */
export function extractDomains(text?: string) {
  if (text === "*") return "*";
  const regex =
    /(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,})(?:\/\S*)?/gi;

  const matches = (text || "").match(regex);
  return matches
    ? matches.map((match) =>
        match.replace(/^(?:https?:\/\/)?(?:www\.)?/, "").replace(/\/.*$/, ""),
      )[0]
    : "";
}

export function arbitrarySort<T>(array: T[], orderArray: T[]): T[] {
  return [...array].sort(
    (a, b) => orderArray.indexOf(a) - orderArray.indexOf(b),
  );
}

export type OffsetSettingsType = "tree" | "percentage";

/**
 * NOTE: This type is largely the same as OffsetPercentageRangeFormData,
 * but numeric form inputs return strings, not numbers,
 * which that type reflects.
 *
 * Changes made here likely need to be made there too.
 */
export type OffsetPercentageRange = {
  amountTo?: number | null;
  amountFrom?: number | null;
  percentage?: number | null;
};

export type ComputeTotalArg = {
  avgCheckoutValue?: number;
  avgCheckoutVolume?: number;
  type?: OffsetSettingsType;
  isPercentagePerRange?: boolean | "0" | "1" | number;
  offsetPercentageRanges?: OffsetPercentageRange[];
  percentagePerOrder?: number;
  isTreePerBatch?: boolean | "0" | "1" | number;
  treePerOrder?: number;
  treePerBatchOrderAmount?: number;
  treePerBatchNumberOfTree?: number;
  plantTreeAlways?: boolean;
  currencyToUse?: string;
};

export function computeIntegrationCost(
  {
    avgCheckoutVolume = 0,
    avgCheckoutValue = 0,
    type,
    percentagePerOrder = 0,
    isPercentagePerRange,
    offsetPercentageRanges = [],
    treePerOrder = 0,
    isTreePerBatch,
    treePerBatchOrderAmount = 0,
    treePerBatchNumberOfTree = 0,
    plantTreeAlways,
  }: ComputeTotalArg,
  treeCost: number,
): BigNumber {
  let total: BigNumber = new BigNumber(0);

  if (type === "percentage") {
    let percentage: BigNumber = new BigNumber(0);
    if (
      isPercentagePerRange === true ||
      isPercentagePerRange === "1" ||
      isPercentagePerRange === 1
    ) {
      let totalPercentage = 0;
      offsetPercentageRanges.forEach((range) => {
        totalPercentage += +(range.percentage ?? 0);
      });
      const nbRanges = Math.max(1, offsetPercentageRanges.length);
      percentage = new BigNumber(totalPercentage).dividedBy(nbRanges);
    } else {
      percentage = new BigNumber(+percentagePerOrder);
    }
    total = new BigNumber(avgCheckoutValue)
      .multipliedBy(percentage)
      .multipliedBy(avgCheckoutVolume);
  } else if (type === "tree") {
    if (
      isTreePerBatch === true ||
      isTreePerBatch === "1" ||
      isTreePerBatch === 1
    ) {
      let trees: BigNumber = new BigNumber(0);
      if (treePerBatchOrderAmount >= 0 && treePerBatchNumberOfTree >= 0) {
        trees = new BigNumber(avgCheckoutValue)
          .dividedBy(+treePerBatchOrderAmount)
          .multipliedBy(+treePerBatchNumberOfTree);
      }
      trees = new BigNumber(Math.floor(trees.toNumber()));
      total = trees.multipliedBy(treeCost).multipliedBy(avgCheckoutVolume);
      // If total is 0 and plantTreeAlways is true, then result = treeCost
      if (total.toNumber() < 1 && plantTreeAlways) {
        total = new BigNumber(treeCost);
      }
    } else {
      total = new BigNumber(treePerOrder)
        .multipliedBy(treeCost)
        .multipliedBy(avgCheckoutVolume);
    }
  }

  return total;
}

export function roundToNearest(
  value: number,
  nearestMultipleOf: number,
  method: "round" | "ceil" | "floor" = "round",
) {
  switch (method) {
    case "round": {
      return Math.round(value / nearestMultipleOf) * nearestMultipleOf;
    }
    case "ceil": {
      return Math.ceil(value / nearestMultipleOf) * nearestMultipleOf;
    }
    case "floor": {
      return Math.floor(value / nearestMultipleOf) * nearestMultipleOf;
    }
  }
}

export function convertToISO8601(
  date: Date | YYYYMMDDDateString | YYYYMMDateString,
) {
  const inputDate = typeof date === "string" ? new Date(date) : date;
  return inputDate.toISOString() as ISO8601DateString;
}

/**
 * convert some string in arbitrary casing / spacing to PascalCase
 * https://stackoverflow.com/a/53952925
 */
export function toPascalCase(str: string) {
  if (/^[\p{L}\d]+$/iu.test(str)) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str
    .replace(
      /([\p{L}\d])([\p{L}\d]*)/giu,
      (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase(),
    )
    .replace(/[^\p{L}\d]/giu, "");
}

export function toPascalCaseAndUriEncode(str: string) {
  return encodeURIComponent(toPascalCase(str));
}

export const generateYYYYMMRange = (
  start: YYYYMMDateString,
  end: YYYYMMDateString,
) => {
  const startDate = convertYYYYMMToDate(start);
  const endDate = convertYYYYMMToDate(end);
  const monthCount = differenceInMonths(endDate, startDate) + 1;

  return Array.from({ length: monthCount }, (_, index) => {
    return convertToYYYYMM(addMonths(startDate, index));
  });
};
